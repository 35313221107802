import React, { Component } from "react";
import { getAPI, postAPI } from "../../api/api-interface";
import DeleteParticipant from "../../Component/Modal/DeleteParticipant";
import EditParticipants from "../../Component/Modal/EditParticipants";

export class NewParticipant extends Component {
  state = {
    feilds: [{ patientName: "", patientStudy_id: "", md: "", mrn: "" }],
    input: false,
    index: "",
    loading: true,
    dummy: [],
  };

  getData = async () => {
    const { studyId } = this.props;
    await getAPI(`/users/participant/${studyId}`).then((res) => {
      if (res.status === 200) {
        const { participant } = res.data;   
        if (participant.length > 0) {
          this.setState({dummy: participant});
          this.setState({ loading: false });
          this.setState({ feilds: participant });
        } else {
          this.setState({ feilds: [{ patientName: "", patientStudy_id: "", md: "", mrn: "" }] });
        }
      }
    });
  };

  componentDidMount() {
    const { studyId, studyUuid } = this.props;
    this.setState({ studyId, studyUuid });
    this.getData();
  }

  addRowHandler = () => {  
    const { feilds } = this.state;
    feilds.push({ patientName: "", patientStudy_id: "", md: "", mrn: "" });
    this.setState({ feilds, ...this.state.feilds });
  };

  newChangeHandler = (index, e) => {
    this.setState({ input: false });
    let { feilds } = this.state;
    feilds[index][e.target.name] = e.target.value;
    this.setState({ feilds });

  };

  saveHandler = () => {
    const { feilds, studyUuid } = this.state;
      const empty =
      feilds &&
      feilds.filter((el) => {
        return el.patientName === "" || el.patientStudy_id === "" || el.md === "" || el.mrn === "";
      });
    if (empty.length > 0) {
      this.setState({ input: true });
    } else {
      postAPI(`/participant/addAllParticipant/${studyUuid}`, feilds)
        .then((res) => {
          if (res.status === 200) {
            alert("Participant Saved Successfully")
            this.componentDidMount();
          }
        })
        .catch((err) => console.log("Failed to add "));
    }
  };


  deleteReload = () => {
    this.componentDidMount();
  };

  editParticipant = (val) => {
    const { feilds } = this.state;
    const edit = feilds.find((el) => {
      return el.id === val;
    });
    this.setState({ editId: edit });
  };

  editParticipantReload = () => {
    this.componentDidMount();
  };

  deleteParticipant = (ele, index) => {
    const { feilds } = this.state;

    const delId = feilds.find((el) => {
      return el.id === ele;
    });

    this.setState({ deleteId: delId });
    this.getData();
  };

  deleteInitailaParticipant = (index) => {
    let values = [...this.state.feilds];
    values.splice(index, 1);
    this.setState({ feilds: values });
  };

  render() {
    const { feilds, dummy } = this.state;

    const sortFeilds = feilds.sort((a, b) => {
      return a.id - b.id;
    });

    return (
      <>
        <div>
          <div>
            <div className="text-right mb-4"></div>
            <div className="Screening_table border radious_4 bg-white table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th className="wid_col">
                      <h5>Patient Name</h5>
                    </th>
                    <th className="wid_col">
                      <h5>Study Id</h5>
                    </th>
                    <th className="wid_col">
                      <h5>MD</h5>
                    </th>
                    <th className="wid_col">
                      <h5>MRN</h5>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {sortFeilds &&
                    sortFeilds.map((ele, index) => {
                      return (
                        <tr>
                          <td>
                            <input
                              type="text"
                              className="form-control f_out"
                              name="patientName"
                              placeholder="Enter Patient Name"
                              value={ele.patientName}
                              onChange={(e) => this.newChangeHandler(index, e)}
                              readOnly = {feilds[index]?.id ? true : false}
                            />
                          </td>
                          {console.log(dummy, feilds)}
                          <td>
                            <input
                              type="text"
                              className="form-control f_out"
                              name="patientStudy_id"
                              placeholder="Enter Study Id"
                              value={ele.patientStudy_id}
                              onChange={(e) => this.newChangeHandler(index, e)}
                              readOnly = {feilds[index]?.id ? true : false}
                            />
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <input type="text" className="form-control mr-3 f_out" name="md" placeholder="Enter MD" value={ele.md} 
                              onChange={(e) => this.newChangeHandler(index, e)}
                              readOnly = {feilds[index]?.id ? true : false}/>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <input type="text" className="form-control mr-3 f_out" name="mrn" placeholder="Enter MRN" value={ele.mrn}
                               onChange={(e) => this.newChangeHandler(index, e)} 
                               readOnly = {feilds[index]?.id ? true : false}/>
                              {ele.id ? (
                                <>
                                  {" "}
                                  <div className="dropdown">
                                    <button className="btn p-0 focus_out font_14" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <span className="fe fe-more-vertical" />
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-top" aria-labelledby="dropdownMenuButton">
                                      <span className="dropdown-item c_pointer" data-toggle="modal" data-target="#EditParticipant" onClick={(e) => this.editParticipant(ele.id)}>
                                        Edit
                                      </span>
                                      <span className="dropdown-item c_pointer" data-toggle="modal" data-target="#DeleteParticipant" onClick={(e) => this.deleteParticipant(ele.id, index)}>
                                        Delete
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="dropdown">
                                    <button className="btn p-0 focus_out font_14" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <span className="fe fe-more-vertical" />
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-top" aria-labelledby="dropdownMenuButton">
                                      <span className="dropdown-item c_pointer" onClick={(e) => this.deleteInitailaParticipant(index)}>
                                        Delete
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                
                  <tr>
                    <td colSpan="4" className="text-center">
                      <button className="c_pointer btn btn-white" onClick={this.addRowHandler} >
                        <span className="fe fe-plus mr-2" />
                        Add 1 More Row
                      </button>
                    </td>
                  </tr>
                  {sortFeilds && sortFeilds.map((el, index) => {
                    return (
                      <div className="save_cancel_button ">
                      <button className="btn btn-primary btn-sm radious_4 save" onClick={this.saveHandler} disabled={feilds[index]?.id ? true: false}>
                        Save
                      </button>
                    </div>
                    )
                  })}
                </tbody>
                <DeleteParticipant {...this.state} deleteReload={this.deleteReload} />
                <EditParticipants {...this.state} {...this.props} editParticipantReload={this.editParticipantReload} />
              </table>
              {this.state.input && (
                <div className="text-center">
                  <p className="text-danger">Please fill all the details</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NewParticipant;
