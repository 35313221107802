import React, { Component } from 'react';

import SearchBar from '../../Component/SearchBar';
import Table from '../../Component/Table';
import CreateNewStudy from '../../Component/Modal/CreateNewStudy';
import AddNewVisitModal from '../../Component/Modal/AddNewVisitModal';

class Dashboard extends Component {
  state={
      search:''
  }
  searchName =(data)=>{
     
      this.setState({search:data})
  }
    render() {
       

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                          
                            <SearchBar searchName={this.searchName} />
                            <Table {...this.props} {...this.state}/>
                            <CreateNewStudy {...this.props} />
                            <AddNewVisitModal />
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default (Dashboard)
