import React, { Component } from 'react'

class SearchBar extends Component {
    state = {
        searchName: ''
    }
    searchHandler = (e) => {
        this.setState({ searchName: e.target.value })

        this.props.searchName(e.target.value)
    }

    render() {
        return (
            <>
                <div className="search_section d-flex align-items-center p-3 mt-3">
                    <div className="input-group input-group-flush input-group-merge input-group-reverse border radious_4 mr-4 h-100 bg-white">
                        <span className="input-group-text border-0">
                            <i className="fe fe-search" />
                        </span>
                        <input className="form-control list-search" type="search" placeholder="Search Studies" value={this.state.searchName} onChange={this.searchHandler} />
                    </div>
                    <button type="button" className="btn btn-primary btn-lg radious_4 btn_space" data-toggle="modal" data-target="#createNewStudy">
                        Create New Study
                    </button>
                </div>
            </>
        )
    }
}
export default (SearchBar)
