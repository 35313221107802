import React from "react";
import EmptyScreen from "./EmptyScreen";
import Screening from "./Screening";

const NewPhase = (props) => {
  const { name } = props;

  return name !== "" ? <Screening {...props} /> : <EmptyScreen />;
};

export default NewPhase;
