import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import CreateStudy from '../../Component/Modal/Modal';
import UploadModal from '../../Component/Modal/UploadModal';


class CreateNewStudy extends Component {
    state = {
        isUploadModal: false
    }


    change = () => {
        const value = document.querySelector('input[name="upload_cptm"]:checked').value;
       
        if (value === 'raw') {
            document.querySelector('#reactAccRadio').setAttribute('data-target', '#CreateStudy');
        } else if (value === 'upload_cptm') {
            document.querySelector('#reactAccRadio').setAttribute('data-target', '#UploadModal');
            
        }
    }

    render() {

        return (
            <>
                <div className="modal fade" id="createNewStudy" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h1 className="modal-title w-100" id="exampleModalLabel">Create New Study</h1>
                            </div>
                            <div className="modal-body py-5">
                                <div className="row create_options">
                                    <div className="col-sm-8 offset-sm-2">
                                        <div className="row">
                                            <div className="form-check col-sm-6">
                                                <input className="form-check-input" type="radio" name="upload_cptm" value='raw' id="raw" onClick={this.change} />
                                                <label className="form-check-label pl-2" htmlFor="raw">
                                                    Create Raw document
                                                </label>
                                            </div>
                                            <div className="form-check col-sm-6">
                                                <input className="form-check-input" type="radio" name="upload_cptm" value='upload_cptm' id="upload_cptm" onClick={this.change} />
                                                <label className="form-check-label pl-2" htmlFor="upload_cptm">
                                                    Upload CPTM
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary mx-auto" id="reactAccRadio" data-toggle="modal" data-target="#CreateStudy">Begin Building Study</button>
                            </div>
                        </div>
                    </div>
                </div>

                <CreateStudy {...this.props} />
                <UploadModal />
            </>
        )
    }
}
export default withRouter(CreateNewStudy)

