import React, { Component } from 'react'
import { Link } from 'react-router-dom';


import { postAPI } from '../../api/api-interface';

class ForgetPassword extends Component {

  state = {
    reset: false,
    email: '',
    isEmail: false,
    notFound: false
  }
  forgotPassword = (e) => {
    e.preventDefault();

    const email = this.state.email;
   
    if (email) {
      postAPI('/users/forgotPassword', { email })
        .then((res => {
          let loginApiResponseIs = res.status;
        

          if (loginApiResponseIs === 200) {
            this.setState({ reset: true })
           
           

          }
          if (loginApiResponseIs === 201) {
            this.setState({ notFound: true })
          }


        })).catch((error) => {
        
        
          alert('Server error. Please try again.')
        })



    } else {
      this.setState({ isEmail: true })
    }

  }
  render() {
    return (
      <>
        <div className="d-flex align-items-center bg-auth border-top border-top-2 border-primary h_100vh">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-5 col-xl-4 my-5">

                <h1 className="display-4 text-center mb-3">Password reset</h1>
                {/* <p className="text-muted text-center mb-5">
                  Enter your email to get a password reset link.
                </p> */}
                {this.state.reset && <h2 className="display-5 text-center mb-3 text-danger">Password reset link sent to your email</h2>}

                {!this.state.reset && <form>
                  <div className="form-group">
                    <label>Email Address</label>
                    <input type="email" className="form-control" placeholder="name@address.com" value={this.state.email} onChange={e => this.setState({ email: e.target.value, isEmail: false, notFound: false })} />
                  </div>
                  <button className="btn btn-lg btn-block btn-primary mb-3" type='submit' onClick={e => this.forgotPassword(e)}>Reset Password</button>
                  {this.state.isEmail && <div className="text-center">
                    <p className='text-danger'>Enter Your Email</p>
                  </div>}
                  {this.state.notFound && <div className="text-center">
                    <p className='text-danger'>Email not found</p>
                  </div>}
                  <div className="text-center">
                    <small className="text-muted text-center">
                      Remember your password? <Link to="/">Sign in</Link>.
                    </small>
                  </div>
                </form>}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default (ForgetPassword)


