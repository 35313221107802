 import React, { Component } from 'react'
 import EmptyScreenImage from '../../Assets/img/Empty_screen.svg';
import AddNewVisitModal from '../../Component/Modal/AddNewVisitModal';
 import Modal from '../../Component/Modal/Modal';
 
class EmptyScreen extends Component {
     render() {
         return (
              <>
                <section id="empty">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <div className="empty_image">
                                    <img src={EmptyScreenImage} alt="logo" className="img-fluid" />
                                </div>
                                <p className="my-4">There is no data for this phase. Add visit data to this phase to record new data.</p>
                                <button type="button" className="btn btn-primary btn-lg radious_4 btn_space" data-toggle="modal" data-target="#AddNewVisit">Add New Visit</button>
                            </div>
                        </div>
                    </div>
                    <Modal/>
                    <AddNewVisitModal />
                </section>
              </>
         )
     }
 }
 export default (EmptyScreen)
 