import React, { Component } from "react";
import DeleteStudy from "../../Component/Modal/DeleteStudy";
import TrackingModal from "../../Component/Modal/TrackingModal";
import EditVisit from "../../Component/Modal/EditNewVisitModal";
import DublicateVisitModal from "../../Component/Modal/DublicateVisitModal";
import SaveAndCancel from "../../Pages/CaseStudy/SaveAndCancel";
import DeleteStudyModal from "../../Component/Modal/DeleteVisitModal";
import AddNewVisitModal from "../../Component/Modal/AddNewVisitModal";
import { getAPI, deleteAPI } from "../../api/api-interface";
import EmptyScreenImage from "../../Assets/img/Empty_screen.svg";
import EditNewVisitModal from "../../Component/Modal/EditNewVisitModal";

class Screening extends Component {
  state = {
    visitData: [],
    arr: [],
    selectedService: "",
    deleteVisitName: "",
    visitDetails: [],
    fields: false,
    phaseName: "",
    studyUuid: "",
    loading: true,
    emptyScreen: false,
    deleteVisit: false,
    downloadId: "",
    button: false,
    isClicked: false,
    editData: "",
    duplicateVisitData: "",
    finalFilterData:[]
  };
  componentDidMount() {
    const { name, uuid } = this.props;
  
    this.setState({ phaseName: name, studyUuid: uuid });
    getAPI(`/users/Visit/${uuid}/${name}`)
      .then((res) => {
       
      

        if (res.data.length > 0) {
          
          this.setState({ arr: res.data, loading: false, emptyScreen: false });
        } else {
          this.setState({ emptyScreen: true, loading: false });
        }
      })
      .catch((error) => {
       
        this.setState({ loading: false });
      });
  }

  downloadForm = (index) => {
    const { arr } = this.state;
    const download = arr.find((ele) => ele.id === index);
    this.setState({ downloadId: download });
  };

  visitModalData = () => {
    this.componentDidMount();
    this.setState({ loading: true });
  };
  checkFields = (val) => {
    this.setState({ fields: val });
  };

  onChange = (id, vId, val, service,index) => {
    
    const { arr } = this.state;
    // Clinical service suggesation Start//

    const dataIs = arr.map((ele)=>{
      return ele.visit_data
    })
    const filterData = dataIs.flat(1)
    console.log("data", dataIs)
    console.log("filterData", filterData)
    const filterDataIs = filterData.filter((ele)=>{
     return ele.serviceUnit === val
    })
    const newFilterDataIs = [...filterDataIs.reduce((map,obj) => {return map.set(obj.clinicalService,obj)}, new Map()).values()]

    this.setState({finalFilterData:newFilterDataIs})
  
    // Clinical service suggesation End//
   
    this.setState({ fields: false });
    const obj = arr.find((el) => el.id === vId);
    const { visit_data } = obj;
    const newObj = visit_data.find((el) => el.id === id);
    
    if (service === "clinicalService") {
      newObj.clinicalService = val;
    } else if (service === "location") {
      newObj.location = val;
    } else if (service === "comments") {
      newObj.comments = val;
    } else if (service === "serviceUnit") {
      newObj.serviceUnit = val;
    }
  };
  addRow = (vId) => {

    const { arr } = this.state;
    arr.forEach((element, index) => {
      if (element.visit_name === vId) {
        let { visit_data } = element;
        if (visit_data.length === 0) {
          visit_data.push({ id: 1, clinicalService: "", location: "", comments: "", serviceUnit: "" });
        } else {
          visit_data.push({ id: visit_data[visit_data.length - 1].id + 1, clinicalService: "", location: "", comments: "", serviceUnit: "" });
        }
      }
    });
    this.setState({ arr: [...arr] });
    this.setState({ button: false });
  };
  deleteServiceRow = () => {
    const { arr, selectedService, deleteVisitName } = this.state;
    const delRowObj = arr.find((el) => el.visit_name === deleteVisitName);
    const { visit_data } = delRowObj;
    this.setState({ fields: false });
    const index = visit_data.indexOf(visit_data.find((el) => el.id === Number(selectedService)));
 
    if (index > -1) {
      visit_data.splice(index, 1);
    }

  
    this.setState({ arr: [...arr] });
  };
  deleteVisitFunc = () => {
    const { name, uuid } = this.props;
    
    const { arr, deleteVisitId } = this.state;
    const deleteVisit = arr.indexOf(arr.find((el) => el.id === deleteVisitId));
    
    if (deleteVisit > -1) {
      deleteAPI(`/users/${uuid}/${name}/${deleteVisitId}`)
        .then((res) => {
         
          if (res.status === 200) {
            arr.splice(deleteVisit, 1);
            this.setState({ arr: [...arr] });
            this.setState({ loading: true });
            this.componentDidMount();
            alert("Visit deleted");
          }
        })
        .catch((err) => {
          alert("Server error ,Try again");
        });
    }
  };

  cancelData = () => {
    this.componentDidMount();
    this.setState({ fields: false });
  };

  editHandler = (val) => {
    const { arr } = this.state;
    
    const obj = arr.find((m) => m.id === val);
   
    this.setState({ editData: obj });
  };

  duplicateVisitHandler = (val) => {
    const { arr } = this.state;
    const obj = arr.find((m) => m.id === val);
    this.setState({ duplicateVisitData: obj });
    
  };

  dublicateService = (val, index) => {
   

    const { arr } = this.state;
    const object = arr.find((f) => f.id === val);
    let { visit_data } = object;
    const a = visit_data.find((element) => element.id === index);
  
    visit_data.push({ id: visit_data[visit_data.length - 1].id + 1, clinicalService: a.clinicalService, location: a.location, comments: a.comments, serviceUnit: a.serviceUnit });

    this.setState({ arr: [...arr] });
  };

  reloadScreen = () => {
    this.componentDidMount();
  };
  refresh = () => {
    this.componentDidMount();
  };

  render() {
    const { loading, emptyScreen, arr, finalFilterData } = this.state;
   

    const sortArr = arr.sort((a, b) => {
      return a.id - b.id;
    });

    const serviceUnits = ["Laboratory Test", "Research Procedure", "Imaging", "Pathology", "Treatment", "Assessment"];
    // let serArray = serviceUnits.map((m) => {
    //   return { label: m, value: m };
    // });

    const serDataIs =
      arr &&
      arr.map((ele) => {
        return ele.visit_data;
      });
      // console.log("object", serDataIs);

    let serviArray = [];
    serDataIs &&
      serDataIs.map((val) => {
        return Object.values(val).map((el) => serviArray.push(el.clinicalService));
      });
    serviArray = [...new Set(serviArray)];

    if (emptyScreen)
      return (
        <>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <div className="empty_image">
                    <img src={EmptyScreenImage} alt="logo" className="img-fluid" />
                  </div>
                  <p className="my-4">There is no data for this phase. Add visit data to this phase to record new data.</p>
                  <button type="button" className="btn btn-primary btn-lg radious_4 btn_space" data-toggle="modal" data-target="#AddNewVisit">
                    Add New Visit
                  </button>
                </div>
              </div>
              <AddNewVisitModal visitModalData={this.visitModalData} {...this.props} />
            </div>
          </section>
        </>
      );
    else {
      return (
        <>
          {!loading ? (
            <div>
              <div className="text-right mb-4">
                <button type="button" className="btn btn-primary btn-lg radious_4 btn_space" data-toggle="modal" data-target="#AddNewVisit">
                  Add New Visit
                </button>
              </div>
              <div className="Screening_table border radious_4 bg-white table-responsive">
                {sortArr.map((visit, index) => {
                  const { visit_name, duration, tolerance_window, id, visit_data } = visit;

                  return (
                    <table className="table mb-0" key={index}>
                      <thead>
                        <tr>
                          <th className="wid_col">
                            <h5>visit Name</h5>
                            <h6>{visit_name}</h6>
                          </th>
                          <th className="wid_col">
                            <h5>Duration</h5>
                            <h6>{duration}</h6>
                            
                          </th>
                          <th className="wid_col">
                            <h5>Tolerance Windows</h5>
                            <div className="d-flex align-items-center mr-2 slash_clr">
                              <span className="fe fe-plus" />
                              <span className="slash"></span>
                              <span className="fe fe-minus mr-2" /> {tolerance_window}
                            </div>
                          </th>
                          <th className="text-right wid_last">
                            {/* <button type="button" className="btn btn-outline-primary btn-lg radious_4 btn_space" data-toggle="modal" data-target="#EditVisit">
                                        Edit Visit Details
                                    </button> */}
                            <div className="dropdown">
                              <button className="btn p-0 focus_out font_14" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="fe fe-more-vertical" />
                              </button>
                              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <span
                                  className="dropdown-item text-capitalize font-12 c_pointer"
                                  data-toggle="modal"
                                  data-target="#DublicateVisitModal"
                                  onClick={() => this.duplicateVisitHandler(visit.id)}
                                >
                                  Duplicate
                                </span>
                                <span className="dropdown-item text-capitalize c_pointer" data-toggle="modal" data-target="#EditVisit" 
                                onClick={() => this.editHandler(visit.id)}>
                                  Edit
                                </span>
                                <button
                                  className="dropdown-item text-capitalize c_pointer"
                                  data-toggle="modal"
                                  data-target="#DeleteVisitModal"
                                  onClick={() => this.setState({ deleteVisit: true, deleteVisitId: id })}
                                >
                                  Delete
                                </button>
                                <span className="dropdown-item text-capitalize c_pointer" data-toggle="modal" data-target="#exampleModalLong" onClick={() => this.downloadForm(visit.id)}>
                                  Download Tracking Form
                                </span>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Service unit</td>
                          <td>Clinical Service</td>
                          <td>Location</td>
                          <td>Comments</td>
                        </tr>

                        {visit_data.map((vis, index) => {
                         
                          return (
                            <tr key={index}>
                              {/* <td>
                                <Select
                                  value={vis.serviceUnit && { label: vis.serviceUnit, value: vis.serviceUnit }}                          
                                  options={serArray}
                                  onChange={(e) => this.onChange(vis.id, visit.id, e, "serviceUnit")}
                                  placeholder={"Select Service Unit"}
                                />
                              </td> */}
                              <td>
                                <select className="form-control f_out" onChange={(e) => this.onChange(vis.id, visit.id, e.target.value, "serviceUnit",index)}>
                                
                                  {vis.serviceUnit && vis.serviceUnit !== "" ? (
                                    <option>{vis.serviceUnit}</option>
                                  ) : (
                                    <option disabled selected id="selectColor" >
                                      Select Service Unit
                                    </option>
                                  )}
                                  {serviceUnits &&
                                    serviceUnits.map((ele, index) => {
                                      return <option key={index}>{ele}</option>;
                                    })}
                                </select>
                              </td>
                              <td>
                              
                                <input
                                  type="text"
                                  // name="modelservice"
                                  contenteditable="true"
                                  list="modelservic"
                                  className="form-control f_out"
                                  placeholder="Clinical Service"
                                  value={vis.clinicalService}
                                  onChange={(e) => this.onChange(vis.id, visit.id, e.target.value, "clinicalService",index)}
                                />
                                <datalist id="modelservic">
                                  { finalFilterData?.map((prop, index) => {
                                   
                                    return (
                                      <option key={index} value={prop.clinicalService}>
                                        {prop.clinicalService}
                                      </option>
                                    );
                                  })}
                                </datalist>
                              
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control f_out"
                                  placeholder="Enter Location"
                                  value={vis.location}
                                  onChange={(e) => this.onChange(vis.id, visit.id, e.target.value, "location",index)}
                                />
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="form-control mr-3 f_out"
                                    placeholder="Enter comments"
                                    value={vis.comments}
                                    onChange ={(e) => this.onChange(vis.id, visit.id, e.target.value, "comments",index)}
                                  />
                                  <div className="dropdown">
                                    <button className="btn p-0 focus_out font_14" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <span className="fe fe-more-vertical" />
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                      <span className="dropdown-item c_pointer" onClick={() => this.dublicateService(visit.id, vis.id)}>
                                        Duplicate Service
                                      </span>
                                      <button
                                        className="dropdown-item c_pointer"
                                        data-toggle="modal"
                                        data-target="#DeleteVisitModal"
                                        onClick={() => this.setState({ selectedService: vis.id.toString(), deleteVisitName: visit_name })}
                                      >
                                        Delete Service
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan="4" className="text-center">
                            <button className="c_pointer btn btn-white" onClick={() => this.addRow(visit_name)}>
                              <span className="fe fe-plus mr-2" />
                              Add 1 More Row
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
                {this.state.fields && (
                  <div className="text-center">
                    <p className="text-danger">Please fill all the details</p>
                  </div>
                )}
              </div>
             
              <EditNewVisitModal {...this.state.editData} reloadScreen={this.reloadScreen} />
              <EditVisit />
              <DeleteStudy />
              <TrackingModal {...this.state.downloadId} />
              <DublicateVisitModal {...this.state} {...this.props} refresh={this.refresh} />
              <SaveAndCancel {...this.state} checkFields={this.checkFields} cancelData={this.cancelData} changeValue={this.state.button} />
              <DeleteStudyModal deleteServiceRow={!this.state.deleteVisit ? this.deleteServiceRow : this.deleteVisitFunc} />
              <AddNewVisitModal visitModalData={this.visitModalData} {...this.props} />
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-dark  " role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}

export default Screening;


