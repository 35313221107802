import React, { Component } from "react";
import { getAPI, postAPI } from "../../api/api-interface";
import Select from "react-select";
import { saveAs } from "file-saver";

export default class Tracking extends Component {
  state = {
    studyName: "",
    laboratory: [],
    research: [],
    imagng: [],
    pathology: [],
    treatmnt: [],
    assess: [],
    visitArr: [],
    currentDateTime: Date().toLocaleString(),
    hospitalName: "",
    firstName:'',
    lastName:'',
    participant:[],
    patientName:[],
    uuid:'',
    patientStudy_id:'',
    md:'',
    mrn:''
  };


  componentDidUpdate(preState) {
    if (preState !== this.props) {    
      this.setState({ patientName: [{label:"", value:""}], patientStudy_id:'', md:'', mrn:''})
      const { study_name, visit_data } = this.props;
      this.setState({ studyName: study_name, visitArr: visit_data });
      
      const serviceData =
        visit_data &&
        visit_data.filter((ele) => {
          return ele.serviceUnit === "Laboratory Test";
        });
      this.setState({ laboratory: serviceData });

      const clinicData =
        visit_data &&
        visit_data.filter((ele) => {
          return ele.serviceUnit === "Research Procedure";
        });
      this.setState({ research: clinicData });

      const imagingData =
        visit_data &&
        visit_data.filter((ele) => {
          return ele.serviceUnit === "Imaging";
        });
      this.setState({ imagng: imagingData });

      const pathologyData =
        visit_data &&
        visit_data.filter((ele) => {
          return ele.serviceUnit === "Pathology";
        });
      this.setState({ pathology: pathologyData });

      const treatData =
        visit_data &&
        visit_data.filter((ele) => {
          return ele.serviceUnit === "Treatment";
        });
      this.setState({ treatmnt: treatData });

      const assessmentData =
        visit_data &&
        visit_data.filter((ele) => {
          return ele.serviceUnit === "Assessment";
        });
      this.setState({ assess: assessmentData });
    }
  }

  componentDidMount() {
       getAPI("/users/settings")
      .then((res) => {
        const { data } = res.data;

        const { hospitalName , firstName, lastName} = data;
        this.setState({ hospitalName , firstName, lastName});
      })
      .catch((err) => {
        err && alert(err);
      });
  }
  

   componentWillReceiveProps(nextProps){
    const {StudyId} = nextProps
    if(StudyId){
      getAPI(`/users/allParticipant/${StudyId}`).then((res) => {
        if (res.status === 200) {
          const { participant } = res.data;
          let patientName = participant.map((ele)=>{
            return {value:ele.patientName, label:ele.patientName}
          })
          this.setState({patientName:patientName})
          this.setState({ participant });
        }
      });
    }
    
  }
    
  selectNameAndvalues = (e) => {
    const {participant} = this.state  
    if(e){
      const obj = participant && participant.find((ele) => ele.patientName === e.value)
      const {patientStudy_id, md, mrn} = obj
      this.setState({patientStudy_id, md, mrn})
     
    }
    else{
      // this.setState({displayForm:false})
    }

  }      
  
  customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
      borderRadius:0,
      boxShadow: 'none',
      outline:'none',
      minWidth:150,
      margin:0
     
    })
  }
        
  render() {
   
    const { studyName, laboratory, research, imagng, pathology, treatmnt, assess ,  patientName, patientStudy_id, md, mrn} = this.state;
 
    return (
      <div className="wp_tracking">
        <div className="header">
          {/* <div className="header-body "> */}
          <div className="header-body pb-0 pt-0 mt-0">
            <div className="row align-items-center">
              <div className="col">
                {/* <h6 className="header-pretitle">FORTIS INSTITUTE OF MEDICAL SCIENCES</h6> */}
                <h6 className="header-pretitle">{this.state.hospitalName}</h6>
                <h3 className="header-title mb-4">{studyName}</h3>
              </div>
              {/* <div className="col-auto ">
              <button className="btn btn-white lift " onClick={this.generatePdf}>
                Download PDF
              </button>
              </div> */}
            </div>
          </div>
        </div>
        
        <div className="card_body mb-5">
          <div className="row form_label">
            
            <div className="col-sm-12 col-md-5 pr_small">
              <div className="form-group d-flex align-items-end ">
                <label htmlFor="patient_name" className="mb-0">
                  Patient Name:
                </label>
                {/* <input type="text" className="form-control border-0 input_tracking f_out" id="patient_name" value= {`${firstName} ${lastName}`}  disabled /> */}
                <Select
                options = { patientName }
                isSearchable = {true}
                onChange = {this.selectNameAndvalues}
                placeholder = '' 
                styles = {this.customStyles}
                components={{ IndicatorSeparator:() => null }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-2 pr_small d-flex">
              <div className="form-group d-flex align-items-end ">
                <label htmlFor="study_id" className="mb-0">
                  Study Id:
                </label>
                <input type="text" className="form-control border-0 input_tracking f_out" id="study_id" value={patientStudy_id} disabled />
              </div>
            </div>
            <div className="col-sm-12 col-md-2 pr_small d-flex">
              <div className="form-group d-flex align-items-end">
                <label htmlFor="id" className="mb-0">
                  MD:
                </label>
                <input type="text" className="form-control border-0 input_tracking f_out" id="id" value={md} disabled />
              </div>
            </div>
            <div className="col-sm-12 col-md-2 d-flex">
              <div className="form-group d-flex align-items-end">
                <label htmlFor="MRN" className="mb-0">
                  MRN:
                </label>
              <input type="text" className="form-control border-0 input_tracking f_out" id="MRN" value={mrn} disabled />
              </div>
            </div>
          </div>
         
          <div className="row">
            <div className="col-12">
              {laboratory && laboratory?.length > 0 ? (
                <div className="table-responsive table_">
                  <small className="text-dark">Blood Specimen:{laboratory && laboratory[0]?.serviceUnit}</small>
                  <table className="table">
                    {laboratory &&
                      laboratory.map((lab) => {
                        return (
                          <tbody>
                            <tr>
                              <td className="td_wid_tracking">
                                {/* {lab.clinicalService} ( {lab.comments} ) */}
                                {lab.clinicalService} {lab.comments && lab.comments.length ? `(${lab.comments})` : ''}
                              </td>
                              <td className="">
                                <div className="d-flex align-items-center">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox2">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </table>
                </div>
              ) : (
                ""
              )}

              {research && research?.length > 0 ? (
                <div className="table-responsive">
                  <small className="text-dark">{research && research[0]?.serviceUnit}</small>
                  <table className="table">
                    {research &&
                      research.map((ele) => {
                        return (
                          <tbody>
                            <tr>
                              <td className=" td_wid_tracking">{ele && ele?.clinicalService}</td>
                              <td className="">
                                <div className="d-flex align-items-center">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox25" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox25">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox26" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox26">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </table>
                </div>
              ) : (
                ""
              )}

              {imagng && imagng?.length > 0 ? (
                <div className="table-responsive">
                  <small className="text-dark">{imagng && imagng[0]?.serviceUnit}</small>
                  <table className="table">
                    {imagng &&
                      imagng.map((element) => {
                        return (
                          <tbody>
                            <tr>
                              <td className=" td_wid_tracking">{element && element?.clinicalService}</td>
                              <td className="">
                                <div className="d-flex align-items-center">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox25" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox25">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox26" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox26">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </table>
                </div>
              ) : (
                ""
              )}
              {pathology && pathology?.length > 0 ? (
                <div className="table-responsive">
                  <small className="text-dark">{pathology && pathology[0]?.serviceUnit}</small>
                  <table className="table">
                    {pathology &&
                      pathology.map((element) => {
                        return (
                          <tbody>
                            <tr>
                              <td className=" td_wid_tracking">{element && element?.clinicalService}</td>
                              <td className="">
                                <div className="d-flex align-items-center">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox25" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox25">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox26" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox26">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </table>
                </div>
              ) : (
                ""
              )}
              {treatmnt && treatmnt?.length > 0 ? (
                <div className="table-responsive">
                  <small className="text-dark">{treatmnt && treatmnt[0]?.serviceUnit}</small>
                  <table className="table">
                    {treatmnt &&
                      treatmnt.map((element) => {
                        return (
                          <tbody>
                            <tr>
                              <td className=" td_wid_tracking">{element && element?.clinicalService}</td>
                              <td className="">
                                <div className="d-flex align-items-center">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox25" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox25">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox26" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox26">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </table>
                </div>
              ) : (
                ""
              )}
              {assess && assess?.length > 0 ? (
                <div className="table-responsive">
                  <small className="text-dark">{assess && assess[0]?.serviceUnit}</small>
                  <table className="table">
                    {assess &&
                      assess.map((element) => {
                        return (
                          <tbody>
                            <tr>
                              <td className=" td_wid_tracking">{element && element?.clinicalService}</td>
                              <td className="">
                                <div className="d-flex align-items-center">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox25" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox25">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox26" defaultValue="option1" disabled />
                                    <label className="form-check-label" htmlFor="inlineCheckbox26">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </table>
                </div>
              ) : (
                ""
              )}
              {/* <hr className="my-5" /> */}
              <h6 className="text-uppercase">Notes</h6>
              <p className="text-muted mb-0">This tracking form was generated using Concordare on {this.state.currentDateTime}.<br/> Copyright Concordaretrials.com</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
