import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { postAPI } from '../api/api-interface';
import ReactPaginate from 'react-paginate';



class Table extends Component {
    state = {
        studies: [],
        loading: true,
        itemsPerPage: 10,
        currentPage: 0,
        pageCount: 0,
        

    }
    componentDidMount() {
       
        const token = localStorage.getItem('myToken')
        postAPI(`/users/getStudy?size=${this.state.itemsPerPage}&page=${0}&search=${this.props.search}`, { token}).then((res => {
            let loginApiResponseIs = res.status;
           
            if (loginApiResponseIs === 200) {
              
                 this.setState({ studies:res.data.studies, loading: false,pageCount:res.data.totalCount })
            }
            else if (loginApiResponseIs === 204) {
 
                console.log('there are no studies for user')
                this.setState({ loading: false })
                
            }
            else {
                this.setState({ loading: false })
            }
        })).catch((error) => {
            console.log(error);
            this.setState({ loading: false })
        })
    }
     
    
    handlePageClick = () => {
        const token = localStorage.getItem('myToken')
        
        const { itemsPerPage, currentPage } = this.state;
        postAPI(`/users/getStudy?size=${itemsPerPage}&page=${currentPage}&search=${this.props.search}`,{token}).then((res)=>{
            let loginApiResponseIs = res.status;
           
            if (loginApiResponseIs === 200) {
               
                 this.setState({ studies:res.data.studies, loading: false ,pageCount:res.data.totalCount})
            }
            else if (loginApiResponseIs === 204) {
             
                this.setState({ loading: false })
            }
            else {
                this.setState({ loading: false })
            }
        }).catch(error=>{
            console.log(error);
            this.setState({loading:false})
        })
    }

    
    componentDidUpdate(pP){
        if (pP.search !== this.props.search) {
            this.setState({loading:true})
            this.handlePageClick();

     }
    }

    change=(e)=>{
       
      this.setState({currentPage:e.selected},()=>this.handlePageClick())
    }
    render() {
       
        const { studies, loading } = this.state;
        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // const finalStudies = studies.filter(el=>el.study_name.startsWith(this.props.search))
        
        return (
            <>
                {!loading ?
                    <div className="table_section p-3">
                        <div className=" border radious_4 table-responsive bg-white">

                            <table className="table cs_table mb-0">
                                <thead>
                                    <tr>
                                        <th>Study Name</th>
                                        <th>Last Modified</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studies.map((study, index) => {
                                        const { study_name, updatedAt, uuid } = study;
                                        const update = new Date(updatedAt);
                                       
                                      
                                        const updatedDate = update.getDate();
                                        const updatedMonth = month[update.getMonth()];
                                        const updatedYear = update.getFullYear()

                                        return <tr key = {index}>
                                            <td>
                                                <Link to={`/study/${uuid}`} className="link_cs">
                                                    {study_name}
                                                </Link>
                                            </td>
                                            <td>
                                                {`${updatedDate} ${updatedMonth} ${updatedYear}`}
                                            </td>
                                        </tr>
                                    })}


                                </tbody>
                            </table>
                        </div>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=" Next "
                            onPageChange={this.change}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            // breakClassName={"break-me"}
                            pageCount={this.state.pageCount}
                            previousLabel=" Previous"
                            renderOnZeroPageCount={null}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                          
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            // activeClassName={'active'}
                          />
                        
                    </div>
                    : <div className='d-flex justify-content-center'>
                        <div className="spinner-border text-dark  " role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div>}


            </>
        )
    }
}
export default (Table)
