import React, { Component } from "react";
import { getAPI, postAPI } from "../../api/api-interface";
import TrackingNew from "../../Pages/CaseStudy/TrackingNew";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import ReactDOMServer from "react-dom/server";
import JSZip, { saveAs } from "jszip";

const base64 = require('base64topdf');
const fs = require('fs')

class DownloadTracking extends Component {
  state = {
    preScreening: [],
    screening: [],
    treatment: [],
    postTreatment: [],
    followUp: [],
    ourCases: [],
    downloadData: [],
    studyUuid: "",
    isClicked: false,
    loading: false,
    formData: [],
  };

  componentWillReceiveProps(nextProps) {
    const { studyUuid } = nextProps;

    getAPI(`/users/visit/${studyUuid}`)
      .then((res) => {
        if (res.status === 200) {
          const { data } = res.data;
          this.setState({ downloadData: data });
          this.setState({ formData: data });
        }
      })
      .catch((err) => {
        err && alert(err);
      });
  }

  dataURItoBlob = (dataURI) => {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  downloadHandler = () => {
    this.setState({ isClicked: true, showTracking: true }, () => {
      setTimeout(() => {
        const allDoms = []
        const task = this.state?.downloadData?.map((ele, index) => {
          const html = document.getElementById("downloadTrackingnew-" + index)
          drawDOM(html, {
            paperSize: "A4",
            scale: 0.6,
            margin: '1cm'
          })
            .then((group) => {
              return exportPDF(group);
            })
            .then((dataUri) => {
              const pdf = this.dataURItoBlob(dataUri.split(";base64,")[1])
              allDoms.push(pdf);
              if (allDoms?.length === this.state?.downloadData?.length) {
                var zip = new JSZip();
                allDoms?.map((el, index) => {
                  // console.log("eleeeeee", ele)
                  const data = Object.values(ele)
                  // console.log("data", data)
                  zip.file(`TrackingForm-${index}.pdf`, el, { base64: true });
                })
                zip.generateAsync({ type: "blob" }).then(function (data) {
                  const url = window.URL.createObjectURL(data);

                  var a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  a.href = url;
                  a.download = "Report.zip";
                  a.click();
                  window.URL.revokeObjectURL(url);
                });
                this.setState({ isClicked: false, showTracking: false })
              }
            });
        })
      }, 500)

    })

  }


  exportPDFWithMethod = () => {
    let gridElement = document.querySelector(".k-grid");
    drawDOM(gridElement, {
      paperSize: "A4",
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        console.log(dataUri.split(";base64,")[1]);
      });
  };

  render() {
    const { formData } = this.state;
    const sortArr = formData.sort((a, b) => {
      return a.id - b.id;
    });
    const data = sortArr && sortArr.map((ele) => { return ele.study_phase })
    const filData = [...new Set(data)]

    // const arrayUniqueByKey = [...(new Set(sortArr.map(item => item.study_phase)))]

    return (
      <>
        <div className="modal fade" id="DownloadTracking" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h1 className="modal-title w-100" id="exampleModalLabel">
                  Download Study Tracking Forms
                </h1>
              </div>
              <div className="modal-body pb-0">
                <div className="row">
                  <div>
                    {filData && filData.length && filData.map((el) => {
                      return <div>
                        <h4>{el}</h4>
                        <ul>
                          {sortArr.filter(ele => ele.study_phase === el).map((element) => {
                            return <li>
                              {element.visit_name}
                            </li>
                          })}
                        </ul>

                      </div>
                    })}
                  </div>               
                </div>
              </div>
              {formData && formData.length > 0 ? (
                <p className="text-center">Click the button below to download all Tracking forms in .zip</p>
              ) : (
                <p className="text-center">There are no studies to download</p>
              )}
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary px-5" onClick={this.downloadHandler}>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.isClicked && this.state.showTracking&&this.state?.downloadData?.map((el, index) => <TrackingNew downloadData={el} index={index} />)
        }
      </>
    );
  }
}
export default DownloadTracking;

