import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { postAPI } from "../../api/api-interface";

class SignUp extends Component {
  state = {
    redirect: false,
    isLoading: false,
    username: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    password2: "",
    notMatch: false,
    fields: false,
    isPasswordShort: false,
    isAlreadyRegister: false,
    slashPasswordIcon: false,
    slashPasswordIcon2: false,
    invalidPassword: false,
    invalidEmail: false,
  };
  validateEmail(email) {
    
    const validateEmail = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/

    return validateEmail.test(String(email).toLowerCase());
  }
  validatePassword(password) {
   
    const validatePassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

    return validatePassword.test(String(password));
  }
  createAccountAPI = (signUpData) => {
      
    /******** signup DB api call ******/
    postAPI("/users/register", signUpData)
      .then((res) => {
        let signUpApiResponseIs = res.status;
       
        let UserToken = res.data.token;

        if (signUpApiResponseIs === "0") {
         
          this.setState({ isLoading: false });
          alert("Account could not be created. Please try again");
        } else if (signUpApiResponseIs === 200) {
          localStorage.setItem("myToken", UserToken);
          alert("Your are registered ");
          this.setState({ redirect: true });
          this.props.reload();
        } else if (signUpApiResponseIs === 201) {
          this.setState({ isAlreadyRegister: true });
        }
      })
      .catch((error) => {
       

        alert("Server error. Please try again.");
      });
  };
  displayPassword = (e) => { 
    
    e.preventDefault();
    const password = document.getElementById("password1");
    if (password.type === "password") {
      password.type = "text";
      this.setState({ slashPasswordIcon: true });
    } else {
      password.type = "password";
      this.setState({ slashPasswordIcon: false });
    }
  };
  displayPassword2 = (e) => {
    e.preventDefault();
    const password2 = document.getElementById("password2");

    if (password2.type === "password") {
      password2.type = "text";
      this.setState({ slashPasswordIcon2: true });
    } else {
      password2.type = "password";
      this.setState({ slashPasswordIcon2: false });
    }
  };

  signUp = (e) => {
    e.preventDefault();

    const { email, password, password2, username } = this.state;
    if (!email || !username || !password || !password2) {
      this.setState({ fields: true });
    } else if (password !== password2) {
      this.setState({ notMatch: true });
    } else if (password.length < 6) {
      this.setState({ isPasswordShort: true });
    } else if (!this.validateEmail(email)) {
      this.setState({ invalidEmail: true });
    } else if (!this.validatePassword(password)) {
      this.setState({ invalidPassword: true });
    } else {
      const data = { email, password, password2, username };
      this.createAccountAPI(data);
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <>
        <div className="d-flex align-items-center bg-auth border-top border-top-2 border-primary h_100vh">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-5 col-xl-4 my-5">
                {/* Heading */}
                <h1 className="display-4 text-center mb-3">Sign up</h1>
                {/* Subheading */}
                <p className="text-muted text-center mb-5">Free access to our dashboard.</p>
                {/* Form */}
                <form action={(e) => this.signUp(e)}>
                  {/* Email address */}
                  <div className="form-group">
                    {/* Label */}
                    <label>Email Address</label>
                    {/* Input */}
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Name@address.com"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value, fields: false, isAlreadyRegister: false, invalidEmail: false })}
                    />
                  </div>
                  <div className="form-group">
                    {/* Label */}
                    <label>Username</label>
                    {/* Input */}
                    <input type="text" className="form-control" placeholder="Enter username" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value, fields: false })} />
                  </div>
                  {/* Password */}
                  <div className="form-group">
                    {/* Label */}
                    <label>Password</label>
                    {/* Input group */}
                    <div className="input-group input-group-merge">
                      {/* Input */}
                      <input
                        type="password"
                        id="password1"
                        className="form-control form-control-appended"
                        placeholder="Enter your password"
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value, fields: false, notMatch: false, isPasswordShort: false, invalidPassword: false })}
                      />
                      {/* Icon */}
                      <div onClick={this.displayPassword} className="input-group-append">
                        <span className="input-group-text">{!this.state.slashPasswordIcon ? <i className="far fa-eye-slash"></i> : <i className="fe fe-eye" />}</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    {/* Label */}
                    <label>Confirm Password</label>
                    {/* Input group */}
                    <div className="input-group input-group-merge">
                      {/* Input */}
                      <input
                        type="password"
                        id="password2"
                        className="form-control form-control-appended"
                        placeholder="Confirm password"
                        value={this.state.password2}
                        onChange={(e) => this.setState({ password2: e.target.value, fields: false, notMatch: false, isPasswordShort: false, invalidPassword: false })}
                      />
                      {/* Icon */}
                      <div onClick={this.displayPassword2} className="input-group-append">
                        <span className="input-group-text">{!this.state.slashPasswordIcon2 ? <i className="far fa-eye-slash"></i> : <i className="fe fe-eye" />}</span>
                      </div>
                    </div>
                  </div>
                  {/* Submit */}
                  <button className="btn btn-lg btn-block btn-primary mb-3" onClick={this.signUp}>
                    Sign up
                  </button>
                  {this.state.fields && (
                    <div className="text-center">
                      <p className="text-danger">Enter the fields </p>
                    </div>
                  )}
                  {this.state.notMatch && (
                    <div className="text-center">
                      <p className="text-danger">Passwords do not match </p>
                    </div>
                  )}
                  {this.state.isPasswordShort && (
                    <div className="text-center">
                      <p className="text-danger">Passwords should be greater than 6 digits </p>
                    </div>
                  )}

                  {this.state.isAlreadyRegister && (
                    <div className="text-center">
                      <p className="text-danger">This email is already registered </p>
                    </div>
                  )}
                  {this.state.invalidEmail && (
                    <div className="text-center">
                      <p className="text-danger">Enter the valid email </p>
                    </div>
                  )}
                  {this.state.invalidPassword && (
                    <div className="text-center">
                      <p className="text-danger">Password should be Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character</p>
                    </div>
                  )}
                  {/* Link */}
                  <div className="text-center">
                    <small className="text-muted text-center">
                      Already have an account? <Link to="/">Sign in</Link>.
                    </small>
                  </div>
                </form>
              </div>
            </div>{" "}
            {/* / .row */}
          </div>{" "}
          {/* / .container */}
        </div>
      </>
    );
  }
}
export default SignUp;
