import React, { Component } from "react";
import { getAPI, postAPI } from "../../api/api-interface";

class DublicateVisitModal extends Component {
  state = {
    duplicateData: "",
    screen: "",
    input: false,
    uuid: "",
    visitId: "",
  };

  componentDidMount() {
    const { uuid } = this.props;
    this.setState({ uuid });

    if (this.state.uuid !== "") {
      getAPI(`/users/visit/${this.state.uuid}`)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res.data;
            this.setState({ duplicateData: data });
          }
        })
        .catch((err) => {
          err && alert(err);
        });
    }
    // else{console.log("object");}
  }

  clickHandler = (val) => {
    const { duplicateVisitData } = this.props;
    const { id } = duplicateVisitData;
    this.setState({ screen: val, input: true, visitId: id });
  };

  saveHandler = () => {
    const { screen } = this.state;
    postAPI(`/users/${this.state.uuid}/${this.state.visitId}`, { selectedPhase: screen })
      .then((res) => {
        if (res.status === 200) {
          alert("Duplicate Visit Created");
          // window.location.reload()
          this.props.refresh();
        }
      })
      .catch((err) => {
        err && alert("Error in duplicating study");
      });
  };

  render() {
    const { newPhase } = this.props;

    return (
      <>
        <div className="modal fade" id="DublicateVisitModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h1 className="modal-title w-100" id="exampleModalLabel">
                  Duplicate Visit
                </h1>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12 text-center main_wrapper">
                    {/* <div className="dublicate_image">
                                            <img src={dublicate_image} alt="dublicate" className="img-fluid" />
                                        </div> */}
                    <p className="mb-4">Select the Phase you want to duplicate this visit into?</p>
                    <div className="visit_list">
                      <div className="dropdown">
                        <button className="btn btn-outline-dark btn-lg dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {this.state.input && this.state.input ? ` ${this.state.screen}` : "Choose State"}
                        </button>

                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          {newPhase &&
                            newPhase.map((ele) => {
                              return (
                                <a className="dropdown-item" href="#" onClick={(e) => this.clickHandler(ele.name)}>
                                  {ele.name}
                                </a>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary px-5" data-dismiss="modal">
                  No
                </button>
                <button type="button" className="btn btn-primary px-5 ml-4" data-dismiss="modal" onClick={this.saveHandler}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default DublicateVisitModal;
