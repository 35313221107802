import React, { Component } from "react";
import { Link } from "react-router-dom";
import Avatar from "../../Assets/img/avatar-1.jpg";
import "./style.css";
import { getAPI, postAPI } from "../../api/api-interface";
// import Success from "../../Component/Modal/Sucess";

const passwordValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const phoneValidator = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
export default class Settings extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    emailErr: "",
    password: "",
    passwordErr: "",
    phoneNumber: "",
    phoneErr:"",
    hospitalName: "",
    selectedFiles: "",
    imgUrl: "",
    pS: "",
    passwordVisibile: false,
    isFormSubmitted: false,
    disableButton: true,
    user: "",
    loading: true,
    isLoading:false,
    inputF:false,
    inputL:false,
    inputP:false,
    inputH:false,
    success:false
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ passwordErr: "" });
    this.setState({ emailErr: "" });
    this.setState({ phoneErr: "" });
    this.setState({inputF:false})
    this.setState({inputL:false})
    this.setState({inputP:false})
    this.setState({inputH:false})
    this.setState({ disableButton: false });

   
  };

  imageUpdated = () => {
    const url = URL.createObjectURL(this.state.selectedFiles);

    if (this.state.imgUrl === "" || this.state.pS === this.state.imgUrl) {
      this.setState({ imgUrl: url });
      this.setState({ pS: url });
    }
  };

  onSubmitHandler = (e) => {
    e.preventDefault();
    const { passwordErr, emailErr } = this.state;
    if (passwordErr === "" && emailErr === "") {
    }

    const { firstName, lastName, password, phoneNumber, hospitalName } = this.state;

    if(firstName === '' ){
      return this.setState({inputF:true})
    }
    if (lastName === ''){
     return this.setState({inputL:true})
    }
    if(phoneNumber === ''){
    return  this.setState({inputP:true})
    }

    if(hospitalName === ''){
      return this.setState({inputH:true})
    }
   
    const data = { firstName, lastName, password, phoneNumber, hospitalName };

    let formFields = ["password", "email", "phoneNumber"];
    formFields.forEach((feild) => {
      const isValidation = this.validateFeild(feild);
      return isValidation;
    });

  
    if (password !== "" ) {
      this.setState({isLoading:true})
      postAPI(`/users/settings`, data)
        .then((res) => {
          if (res.status === 200) {
            this.setState({disableButton:true,password : '' , isLoading:false})
            // this.setState({success:true})
            // setTimeout(() => {
            //     this.setState({success:false})
            // }, 2000);
            alert("Data saved successfully")
            // window.location.href = '/dashboard'
           
          }
        })
        .catch((err) => {
          // err && alert(err);
          console.log(err)
          this.setState({isLoading:false})
        });
    }
  };

  validateFeild(name) {
    let isValid;
    if (name === "password") isValid = this.validatepassword();
    else if (name === "email") isValid = this.validateEmail();
    // else if (name === "phoneNumber") isValid = this.validatePhoneNumber();
    return isValid;
  }
  validatepassword() {
    let passwordErr = "";
    const passIs = this.state.password;
    if (passIs === "") passwordErr = "password is required";
    else if (!passwordValidator.test(passIs)) passwordErr = "password must contain at least 8 characters, 1 number, 1 upper, 1 special character and 1 lowercase!";
    this.setState({ passwordErr, isLoading:false });
    return passwordErr === "";
  }
  validateEmail() {
    let emailErr = "";
    const emailIs = this.state.email;
    if (emailIs === "") emailErr = "Email address is required";
    else if (!emailValidator.test(emailIs)) emailErr = "Email is not valid";
    this.setState({ emailErr });
    return (emailErr = "");
  }

  // validatePhoneNumber() {
  //   let phoneErr = "";
  //   const phoneNumberIs = this.state.phoneNumber;
  //  
  //   if (phoneNumberIs === "") phoneErr = "Phone Number is required";
  //   else if (!phoneValidator.test(phoneNumberIs)) phoneErr = "Please enter valid Phone Number";
  //   this.setState({ phoneErr });
  //   return (phoneErr = "");
  // }

  fileSelected = (e) => {
    e.preventDefault();
    this.setState({ selectedFiles: e.target.files[0] }, () => this.imageUpdated());
  };

  togglePaswordVisible = (e) => {
  
    const { passwordVisibile } = this.state;
    this.setState({ passwordVisibile: !passwordVisibile });
  };

  componentDidMount() {
    getAPI("/users/settings")
      .then((res) => {
  
        const { data } = res.data;
      
        const { name, email, firstName, lastName, hospitalName, phoneNumber } = data;
        this.setState({ loading: false, user: name, email, firstName, lastName, hospitalName, phoneNumber });
      })
      .catch((err) => {
        err && alert(err);
      });
  }

  // cancelHandler = ()=>{
   
  //   window.location.href = '/dashboard'
  // }

  render() {
    const { firstName, lastName, email, password, phoneNumber, hospitalName, imgUrl, passwordErr, emailErr, disableButton, passwordVisibile } = this.state;
   
    return (
      <>
        {/* <div className = 'center' style ={{width:'300px' , margin:'auto', textAlign:'center', justifyContent:'center'}}>{ this.state.success ? <Success success = "Data Saved Successfully"/>:''}</div> */}
        <section className="">
          <div className="container">
            <div className="py-5 px-3">
              <div className="row">
                <div className="col">
                  <h6 className="header-pretitle">Overview</h6>
                  <h1 className="header-title">Account</h1>
                </div>
              </div>
              {!this.state.loading ? (
                <>
                  {" "}
                  <form className="mt-5" onSubmit={this.onSubmitHandler}>
                    <div className="row justify-content-between align-items-center">
                      <div className="col">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <div className="avatar">
                              <img className="avatar-img rounded-circle" src={imgUrl ? imgUrl : Avatar} alt="" />
                            </div>
                          </div>
                          <div className="col ml-n2">
                            <h4 className="mb-1">{this.state.user}</h4>
                            <small className="text-muted">PNG or JPG no bigger than 1000px wide and tall.</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <input
                          //ref={fileInput=>this.fileInput=fileInput}
                          ref="fileInput"
                          type="file"
                          onChange={this.fileSelected}
                          style={{ display: "none" }}
                        />

                        <button className="btn btn-sm btn-primary" onClick={() => this.refs.fileInput.click()}>
                          Upload
                        </button>
                      </div>
                    </div>

                    <hr className="my-5"></hr>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>First Name</label>
                          <input type="text" className="form-control" placeholder = 'Enter First Name' name="firstName" value={firstName} onChange={this.changeHandler} />
                          {this.state.inputF && (
                         <div className="text-center">
                          <p className="text-danger">Enter First Name  </p>
                         </div>
                  )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input type="text" className="form-control" placeholder = 'Enter Last Name' name="lastName" value={lastName} onChange={this.changeHandler} />
                          {this.state.inputL && (
                         <div className="text-center">
                          <p className="text-danger">Enter Last Name  </p>
                         </div>
                  )}
                        </div>
                       
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Email Address</label>
                          <input type="email" className="form-control" name="email" value={email} onChange={this.changeHandler} disabled/>
                        </div>
                        {emailErr && <span className="errorMsg">{emailErr}</span>}
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            type={passwordVisibile ? "text" : "password"}
                            className="form-control form-control-appended"
                            placeholder=" "
                            name="password"
                            value={password}
                            onChange={this.changeHandler}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text profileeye dash">
                              <i className={passwordVisibile ? "fe fe-eye" : "far fa-eye-slash "} onClick={this.togglePaswordVisible}  />
                            </span>
                          </div>
                        </div>
                        {passwordErr && <span className="errorMsg">{passwordErr}</span>}
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input type="number" className="form-control mb-3" placeholder="Enter Phone Number "   name="phoneNumber" value={phoneNumber} onChange={this.changeHandler} />
                       
                        </div>
                        {this.state.inputP && (
                         <div className="text-center">
                          <p className="text-danger">Enter Phone Number  </p>
                         </div>
                  )}
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Hospital Name</label>
                          <input type="text" className="form-control mb-3" placeholder="Enter Hospital Name " name="hospitalName" value={hospitalName} onChange={this.changeHandler} />
                          {this.state.inputH && (
                         <div className="text-center">
                          <p className="text-danger">Enter Hospital Name  </p>
                         </div>
                     )}
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-primary" type="submit" disabled={disableButton}>
                      {/* Save changes */}
                      {this.state.isLoading ? "Saving..." : "Save Changes"}
                    </button>
                   
                  </form>
                  
                  <Link to = '/dashboard'>
                  <button className="btn btn-primary cancel-btn" type="submit" >
                      Cancel
                   </button>
                  </Link>

                
                </>
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-dark  " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
           
          </div>
        </section>
      </>
    );
  }
}
