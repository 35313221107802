import React, { Component } from "react";
import { patchAPI } from "../../api/api-interface";
import Select from 'react-select'




const options = [
  { value: 'Days', label: 'Days' },
  { value: 'Weeks', label: 'Weeks' },
  { value: 'Months', label: 'Months' },
]

class EditNewVisitModal extends Component {
  state = {
    visit_name: "",
    duration: "",
    tolerance_window: "",
    id: "",
    uuid: "",
    study_phase: "",
    formFeild: true,
    input: false,
    selectedOption:null,
    tolerance_option:null
  };

   componentWillReceiveProps(nextProps) {
    const { visit_name, duration, tolerance_window, id, uuid, study_phase } = nextProps;
    this.setState({ visit_name, duration, tolerance_window, id, uuid, study_phase });
    
    let {selectedOption,tolerance_option} = this.state
    selectedOption = options?.filter(item => duration?.split(" ")[1] === item.label)
    tolerance_option = options?.filter(item => tolerance_window?.split(" ")[1] === item.label)
    
    if(selectedOption[0] && tolerance_option[0]) {
      
      this.setState({selectedOption:selectedOption[0]})
      this.setState({tolerance_option:tolerance_option[0]})
    }
    
  }

  onChangeHandler = (e) => {
    if(e.target.value.length==4) return false;
      const reg =  /^[0-9]*$/gm;
      const { name, value } = e.target;     
   
      if (e.target.value ==="" ||reg.test(e.target.value)) {  
        
        this.setState({ [name]: value });
        this.setState({ input: false });
      
      }
  };

  editHandler = () => {
    const { visit_name, id, uuid, study_phase,selectedOption, tolerance_option } = this.state;
    let {duration, tolerance_window } =this.state
    duration = duration?.split(" ")[0]+" "+ selectedOption.label
    tolerance_window = tolerance_window?.split(" ")[0]+" "+ tolerance_option.label

    const data = { visit_name, duration, tolerance_window, id };

    if (!visit_name || !duration || !tolerance_window) {
      this.setState({ input: true });
    } else {
      
      this.setState({ input: false });
    if(visit_name && duration && tolerance_window) {
        patchAPI(`/users/Visit/${uuid}/${study_phase}`, data)
        
        .then((res) => {
          if (res.status === 200) {
            alert("Edit Successfully");
            this.props.reloadScreen();
          }
        })
        .catch((err) => {
          alert(err);
        });
    }else{
        this.setState({input:true})
    }
    }
  };

  handleDurationOptions = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleToleranceOptions = (tolerance_option) => {
    this.setState({ tolerance_option });
  };

  render() {
    const { visit_name, duration, tolerance_window } = this.state;

    return (
      <>
        <div className="modal fade" id="EditVisit" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h1 className="modal-title w-100" id="exampleModalLabel">
                  Edit Visit
                </h1>
                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button> */}
              </div>
              <div className="modal-body pb-0">
                <div className="form_container">
                  <form>
                    <div className="form-group">
                      <label htmlFor="visit_name">Visit Name</label>
                      <input type="text" 
                      className="form-control" 
                      id="visit_name" 
                      placeholder="Visit Name" 
                      name="visit_name" 
                      value={visit_name} 
                      onChange={(e) => this.setState({ visit_name: e.target.value , fields:false})} />
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 form-group new-select">
                        <label htmlFor="duration" className="fullwidth">Duration</label>
                        <input type="text" className="form-control form_input f_out select-gap"  id="duration" placeholder="2" name="duration" value={duration?.split(" ")[0]} onChange={this.onChangeHandler} />
                        <Select                        
                          className="form_input f_out "
                          value={this.state.selectedOption}
                          onChange={this.handleDurationOptions}
                          options={options}
                        />        
                      </div>
                      <div className="col-sm-12 col-md-6 form-group">
                        <label htmlFor="Tolerance_widows">Tolerance Windows</label>
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center mr-2">
                            <span className="fe fe-plus" />
                            <span className="slash"></span>
                            <span className="fe fe-minus" />
                          </div>
                          <input
                            type="text"
                            className="form-control form_input f_out select-gap"
                            id="Tolerance_widows"                                          
                            placeholder="2"
                            name="tolerance_window"
                            value={tolerance_window?.split(" ")[0]}
                            onChange={this.onChangeHandler}                        
                          />
                          <Select 
                          style={{width:'150px'}}                       
                          className="form_input f_out select_pad"
                          value={this.state.tolerance_option}
                          onChange={this.handleToleranceOptions}
                          options={options}
                          defaultValue={options[0]}
                          />
                          
                          
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary mx-auto" data-dismiss={!visit_name || !duration || !tolerance_window ? "" : "modal"} onClick={this.editHandler}>
                  Save
                </button>
              </div>
              {this.state.input && (
                <div className="text-center">
                  <p className="text-danger">All Feilds are required</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default EditNewVisitModal;
