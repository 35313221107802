import React, { Component } from 'react'
import InputMask from 'react-input-mask';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../api/api-interface';

class Modal extends Component {
    state = {
        studentName: '',
        nctName: '',
        principalInvestigator: '',
        enrollment: '',
        fields: false,
        mask: '',
        isStudyExist: false,
        wrong: false,
        loading: false
    }

    submitNewStudy(submitData) {
        this.setState({ loading: true })
        const token = localStorage.getItem('myToken')
        postAPI('/users/newStudy', submitData, token).then((res) => {
            if (res.status === 200) {
                this.setState({ loading: false })
                window.location.reload();


                // this.props.history.push('/dashboard');
            }
            else if (res.status === 202) {
                this.setState({ isStudyExist: true });
                this.setState({ loading: false })

            }
        }).catch((error) => {
            alert(error)
        })

    }
    submit = () => {
        const { studentName, nctName, principalInvestigator, enrollment, mask } = this.state

        if (!studentName || !nctName || !principalInvestigator || !enrollment || !mask) {
            this.setState({ fields: true })
        }
        else {
            const data = { studentName, nctName, principalInvestigator, enrollment, email: this.props.userEmail.email, mask };
            this.submitNewStudy(data);

        }
    }
    // maskChange(e) {
    //     e.preventDefault();
    //    this.setState({mask:e.target.value})

    // }
    beforeMaskedValueChange = (newState, oldState, userInput) => {
        let { value } = newState;
        let selection = newState.selection;

        return {
            value,
            selection
        }

    }
    render() {
        const { studentName, nctName, principalInvestigator, enrollment } = this.state

        return (
            <>
                <div className="modal fade" id="CreateStudy" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h1 className="modal-title w-100" id="exampleModalLabel">Create New Study</h1>
                            </div>
                            <div className="modal-body pb-0">
                                <div className="form_container">
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Study Name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Study Name" value={studentName} onChange={(e) => this.setState({ studentName: e.target.value, fields: false })} />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label htmlFor="NCT">NCT Name</label>
                                                <input type="text" className="form-control" id="NCT" placeholder="04902157" value={nctName} onChange={(e) => this.setState({ nctName: e.target.value, fields: false })} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label htmlFor="principal_investigator">Principal investigator</label>
                                                <input type="text" className="form-control" id="principal_investigator" placeholder="Jone Doe" value={principalInvestigator} onChange={(e) => this.setState({ principalInvestigator: e.target.value, fields: false })} />
                                            </div>

                                        </div>

                                        <div className="row mb-0">
                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label htmlFor="activation_date">Activation Date</label>
                                                <InputMask mask="99/99/9999" className="form-control" id="activation_date" alwaysShowMask="true" value={this.state.mask} onChange={(e) => this.setState({ mask: e.target.value })} beforeMaskedValueChange={this.beforeMaskedValueChange}></InputMask>
                                            </div>
                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label htmlFor="anticipated_enrollment">Anticipated Enrollment</label>
                                                <input type="text" className="form-control" id="anticipated_enrollment" placeholder="50" value={enrollment} onChange={(e) => this.setState({ enrollment: e.target.value, fields: false })} />
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div className="modal-footer d-flex flex-column">
                                {!this.state.loading ? <button onClick={this.submit} type="button" className="btn btn-primary mx-auto"  >Begin Building Study</button> : <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-dark  " role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>}

                                {this.state.fields && <div className="text-center">
                                    <p className='text-danger'>Enter the fields</p>
                                </div>}
                                {this.state.isStudyExist && <div className="text-center">
                                    <p className='text-danger'>This study with same user is already exist</p>
                                </div>}
                            </div>

                        </div>

                    </div>
                </div>


            </>
        )
    }
}
export default withRouter(Modal)
