import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { getAPI, postAPI } from "../../api/api-interface";



const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [match, setMatch] = useState(false);
    const [passwordLength, setPasswordLength] = useState(false);
    const [pageLoad, setPageLoad] = useState(false);
    const [redirect, SetRedirect] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false)
    const [ slashPasswordIcon01, setSlashPasswordIcon01] = useState(false)
    const [ slashPasswordIcon02, setSlashPasswordIcon02] = useState(false)


    const search = useLocation().search;
    const history = useHistory();
    const token = new URLSearchParams(search).get('token');
    const email = new URLSearchParams(search).get('email');

    function validatePassword(password) {
        const validatePassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

        return validatePassword.test(String(password))
    }

    
    const submitPassword = (e) => {
        

     
        const passwordData = { password, email, token };

        if (password === password2) {
            if (password.length > 6) {
                if (!validatePassword(password)) {
                    setInvalidPassword(true)
                } else {
                    postAPI('/users/resetPassword', passwordData)
                        .then((res => {
                            let loginApiResponseIs = res.status;

                            if (loginApiResponseIs === 200) {
                               
                                SetRedirect(true);
                                setPassword('');
                                setPassword2('');
                            } else {
                                alert('Something went wrong. Please try again.');
                                history.push('/forgetPassword');
                            }

                        })).catch((error) => {
                            
                            alert('Server error. Please try again.');
                            history.push('/forgetPassword');
                        })
                }
            }
            else {
                setPasswordLength(true);
                setInvalidPassword(true)
                setPassword('');
                setPassword2('');
            }
        }
        else {
            setMatch(true);
            setPassword('');
            setPassword2('');
        }
    }

        const   newPassword = (e) => { 
       
        e.preventDefault();
        let password = document.getElementById("newPassword01");
       
        if (password.type === "password") {
          password.type = "text";
          setSlashPasswordIcon01( !slashPasswordIcon01  );
        } 
        else {
          password.type = "password";
          setSlashPasswordIcon01( !slashPasswordIcon01);
        }
      };

      const   displayPassword2 = (e) => { 
        e.preventDefault();
        let password = document.getElementById("newPassword02");
        if (password.type === "password") {
          password.type = "text";
          setSlashPasswordIcon02( !slashPasswordIcon02 );
        } else {
          password.type = "password";
          setSlashPasswordIcon02( !slashPasswordIcon02 );
        }
      };

    useEffect(() => {

        getAPI(`/users/resetPassword?token=${token}&email=${email}`).then((res => {
            let resetPasswordResponseIs = res.status;


            if (resetPasswordResponseIs === 200) {
                
                setPageLoad(true);
            } else {
                alert('Password reset link expired ')
                setPageLoad(false);
                history.push('/forgetPassword');


            }

        })).catch((error) => {
           
            // alert('Server error. Please try again.')
            alert('Session Expire.')
            setPageLoad(false);
            // history.push('/forgetPassword');


        })
    }, [email, history, token])
    const loginPage = (e) => {
        e.preventDefault();
        history.push('/');
    }

    const submitForm  =(e)=>{
        e.preventDefault()
    }


    return <>
        {pageLoad &&
            // <div className="container">
            //     <h1>Change Password</h1>
            //     <div >
            //         <label for="newPassword">New Password</label>
            //         <input type="password" id='newPassword' value={password} onChange={e => { setPassword(e.target.value); setPasswordLength(false); setMatch(false) }} /><br /><br />
            //         <label for="newPassword2">Confirm Password</label>
            //         <input type="password" id='newPassword2' value={password2} onChange={e => { setPassword2(e.target.value); setPasswordLength(false); setMatch(false) }} /><br /><br />
            //         <button type='submit' className="btn btn-primary" onClick={submitPassword}>Change password</button>
            //         {redirect && <p className="text-success">Password Updated</p>}
            //         {passwordLength && <p className="text-danger">Password must be greator than 6 digits</p>}
            //         {redirect && <button className="btn btn-success" onClick={loginPage}>Back to login Page </button>}
            //         {match && <p className="text-danger"> Password do not match</p>}
            //         {invalidPassword && <p className="text-danger"> Password must have Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character</p>}
            //     </div>
            // </div>
        

        <div className="d-flex align-items-center bg-auth border-top border-top-2 border-primary h_100vh">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5 col-xl-4 my-5">

                       {!redirect && <h1 className="display-4 text-center mb-3">Reset Password</h1>}
                        <br/>

                        <form onSubmit = {submitForm}>
                            
                          {!redirect && <div>
                            <div className="form-group">
                            

                                
                                <label>New Password</label>
                                {/* <div className="input-group input-group-merge">
                                <input className="form-control" placeholder="New Password" type="password" id='newPassword01' value={password} onChange={e => { setPassword(e.target.value); setPasswordLength(false); setMatch(false) ; setInvalidPassword(false) }} />
                                <div onClick={newPassword} className="input-group-append">
                                <span className="input-group-text">{ !slashPasswordIcon01 ? <i className="far fa-eye-slash"></i> : <i className="fe fe-eye" />}</span>
                                </div>
                           
                                </div> */}
                                                    <div className="input-group input-group-merge">
                      {/* Input */}
                      <input
                      
                        className="form-control form-control-appended"
                        placeholder="New Password" type="password" id='newPassword01' value={password} onChange={e => { setPassword(e.target.value); setPasswordLength(false); setMatch(false) ; setInvalidPassword(false) }}  />
                      {/* Icon */}
                      <div onClick={newPassword}  className="input-group-append">
                        <span className="input-group-text">{ !slashPasswordIcon01 ? <i className="far fa-eye-slash"></i> : <i className="fe fe-eye" />}</span>
                      </div>
                    </div>

                               
                            </div>
                            
                            <div className="form-group">
                                <label>Confirm Password</label>
                                {/* <div className="input-group input-group-merge">

                                <input className="form-control" type="password" placeholder="Confirm Password" id='newPassword02' value={password2} onChange={e => { setPassword2(e.target.value); setPasswordLength(false); setMatch(false) ; setInvalidPassword(false)}} />
                                <div onClick={displayPassword2} className="input-group-append">
                           <span className="input-group-text">{!slashPasswordIcon02 ? <i className="far fa-eye-slash"></i> : <i className="fe fe-eye" />}</span>
                           </div>
                                </div> */}

<div className="input-group input-group-merge">
                      {/* Input */}
                      <input
                      
                        className="form-control form-control-appended"
                        type="password" placeholder="Confirm Password" id='newPassword02' value={password2} onChange={e => { setPassword2(e.target.value); setPasswordLength(false); setMatch(false) ; setInvalidPassword(false)}}  />
                      {/* Icon */}
                      <div onClick={displayPassword2}  className="input-group-append">
                        <span className="input-group-text">{ !slashPasswordIcon02 ? <i className="far fa-eye-slash"></i> : <i className="fe fe-eye" />}</span>
                      </div>
                    </div>



                            </div>
                            <button className="btn btn-lg btn-block btn-primary mb-3" type='submit' onClick={submitPassword}>Reset password</button>
                            </div>}
                            {redirect && <div className="text-center"> <p className="text-success ">Password Updated</p></div>}
                            {passwordLength && <div className="text-center"> <p className="text-danger ">Password must be greater than 6 digits</p></div>}
                            {redirect && <div className="text-center"><button className="btn btn-success " onClick={loginPage}>Back to login Page </button></div>}
                            {match && <div className="text-center"><p className="text-danger "> Password do not match</p></div>}
                            {invalidPassword && <div className="text-center"><p className="text-danger "> Password must have Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character</p></div>}
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
        }
    </>
}

export default ResetPassword;