import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import StudyDetails from "../CaseStudy/StudyDetails";
import StudyPhase from "../CaseStudy/StudyPhase";
import DublicateStudy from "../../Component/Modal/DublicateStudy";
import EditStudy from "../../Component/Modal/EditStudy";
import DeleteStudy from "../../Component/Modal/DeleteStudy";
import DownloadCPTM from "../../Component/Modal/DownloadCPTM";
import TrackingModal from "../../Component/Modal/TrackingModal";
import DownloadTracking from "../../Component/Modal/DownloadTracking";
import { deleteAPI, getAPI } from "../../api/api-interface";
import NewParticipant from "./NewParticipant";
import EditParticipants from "../../Component/Modal/EditParticipants";
import DeleteParticipant from "../../Component/Modal/DeleteParticipant";
import Download from "../../Component/Modal/Download";

class AllCaseStudy extends Component {
  state = {
    study_name: "",
    anticipated_enrollment: "",
    principal_Investigator: "",
    nct_name: "",
    activation_date: "",
    loading: true,
    studyUuid: "",
    // UserId:'',
    studyId: ''
  };
  componentDidMount() {
    const { location } = this.props;
    const [, , uuid] = location.pathname.split("/");
    this.setState({ studyUuid: uuid });


  getAPI(`/users/study/${uuid}`)
      .then((res) => {
        const { study_name, anticipated_enrollment, principal_Investigator, nct_name, activation_date, UserId, id } = res.data.study;
        this.setState({ study_name, anticipated_enrollment, principal_Investigator, nct_name, activation_date, UserId: UserId, studyId: id, loading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }
  deleteStudy = () => {
    deleteAPI(`/users/study/${this.state.studyUuid}`)
      .then((res) => {
        alert("Study deleted");
        this.props.history.push("/dashboard");
      })
      .catch((err) => alert("Server error, Try again"));
  };

  duplicateStudy = () => {
    getAPI(`/users/phase/${this.state.studyUuid}`)
      .then((res) => {
        if (res.status === 200) {
          alert("Duplicate Study created ");
          this.props.history.push("/dashboard");
        }
      })
      .catch((err) => alert("Server error, Try again"));
  }
  render() {
    const { location } = this.props;
    const [, , uuid] = location.pathname.split("/");


    return (
      <>
        {!this.state.loading ? (
          <div className="container-fluid">
            <div className="row">             
              <div className="col-sm-12 col-md-12">
                <nav className="p-3">
                  <ol className="breadcrumb">
                    <h1><li className="breadcrumb-item active" aria-current="page">
                      <Link to="/dashboard">
                        <span className="fe fe-chevron-left mr-1 align_virtical" />
                        Dashboard
                      </Link>
                    </li></h1>
                  </ol>
                </nav>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="header_body pl-3 pr-3">
                  <h2 className="header-title mb-0">{this.state.study_name}</h2>
                </div>
                <div className="tab_section px-3 pt-3">
                  <Tabs className="tab_list_section">
                    <TabList className="nav-overflow mb-0">
                      <Tab>Study Details</Tab>                     
                      <Tab>New Participants</Tab>
                      <Tab onClick={() => this.props.history.push(`/study/${uuid}/phase`)}>Study Phases</Tab>
                      <Tab data-toggle="modal" data-target="#EditStudy">
                        Edit
                      </Tab>
                      {/* <Tab data-toggle="modal" data-target="#DownloadTracking">
                        Tracking Form
                      </Tab> */}
                      {/* <Tab data-toggle="modal" data-target="#DownloadCPTM">
                        Download CPTM
                      </Tab> */}
                      <Tab data-toggle="modal" data-target="#Download">
                        Download
                      </Tab>
                      <Tab data-toggle="modal" data-target="#DublicateStudy">
                        Duplicate Study
                      </Tab>
                      <Tab data-toggle="modal" data-target="#DeleteStudy">
                        Delete Study
                      </Tab>

                    </TabList>
                    <div className="tab_body">
                      <TabPanel>
                        <StudyDetails {...this.state} {...this.props} />
                      </TabPanel>                     
                      <TabPanel>
                      <NewParticipant {...this.state} {...this.props} />
                      </TabPanel>
                      <TabPanel>
                        <StudyPhase {...this.props} />
                      </TabPanel>

                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-dark  " role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <DownloadTracking {...this.state} />
        <EditStudy {...this.state} />
        <EditParticipants />
        <DublicateStudy duplicateStudy={this.duplicateStudy} />
        <DeleteStudy deleteStudy={this.deleteStudy} />
        <DeleteParticipant />
        <DownloadCPTM {...this.state} {...this.props} />
        <TrackingModal study_name={this.state.study_name} {...this.state} {...this.props} />       
        <Download/>
      </>
    );
  }
}

export default AllCaseStudy;
