import React, { Component } from 'react';
import { getAPI } from '../../api/api-interface';
import dublicate_image from '../../Assets/img/Export.svg'
import exportFromJSON from 'export-from-json'
class DownloadCPTM extends Component {
    state = {
        nct_name:'',
        principal_Investigator:'',
        activation_date:'',
        anticipated_enrollment:'',
        study_name:'',
        downloadData:'',
        studyUuid:''
    }
    
    componentWillReceiveProps(nextProps){
        // console.log('nextProps', nextProps)
        const {nct_name,principal_Investigator,activation_date,anticipated_enrollment,study_name, studyUuid} = nextProps
        this.setState({nct_name:nct_name, principal_Investigator:principal_Investigator, activation_date:activation_date, anticipated_enrollment:anticipated_enrollment, study_name:study_name, studyUuid:studyUuid})
        getAPI(`/users/visit/${studyUuid}`).then((res) => {
            // console.log('getRes', res)
            if(res.status === 200){
                const {data} = res.data
                // console.log("allData", data)
                this.setState({downloadData:data})
                
            }
        })
    }
    
    downloadCptm = () => {
    
        const { downloadData} = this.state
        let dataArr = []
        const data = { downloadData }
        // console.log("###############", data)
        dataArr.push(data)
        // console.log("data", dataArr)
        exportFromJSON({ data: this.state.downloadData, fileName: "Study Details", exportType : exportFromJSON.types.csv})


    }
    
    render() {
        // console.log("cptm data", this.props)
        return (
            <>
            <div className="modal fade" id="DownloadCPTM" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h1 className="modal-title w-100" id="exampleModalLabel">Download CPTM</h1> 
                        </div>
                        <div className="modal-body pb-0">
                            <div className="row">
                                <div className="col-sm-12 text-center main_wrapper">
                                    <div className="dublicate_image">
                                        <img src={dublicate_image} alt="dublicate" className="img-fluid" />
                                    </div>
                                    <p className="mb-4">Do you want to download CPTM.csv file of this study?</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn btn-secondary px-5" data-dismiss="modal" >No</button>
                            <button type="button" className="btn btn-primary px-5 ml-4" data-dismiss="modal" onClick = {this.downloadCptm}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
        )
    }
}
export default (DownloadCPTM)
