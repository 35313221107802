import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import DublicateStudy from "../../Component/Modal/DublicateStudy";
import EditStudy from "../../Component/Modal/EditStudy";
import DeleteStudy from "../../Component/Modal/DeleteStudy";
// import DownloadCPTM from "../../Component/Modal/DownloadCPTM";
// import DownloadTracking from "../../Component/Modal/DownloadTracking";
// import TrackingModal from "../../Component/Modal/TrackingModal";

import AddPhaseModal from "../../Component/Modal/AddPhaseModal";
import NewPhase from "./NewPhase";
import { getAPI } from "../../api/api-interface";
import DublicateVisitModal from "../../Component/Modal/DublicateVisitModal";
// import DownloadTracking from "../../Component/Modal/DownloadTracking";

class StudyPhase extends Component {
  state = {
    newPhase: [],
    uuid: "",
    loading: true,
  };
  componentDidMount() {
    const { location } = this.props;
    const [, , uuid] = location.pathname.split("/");
   
    this.setState({ uuid });
    getAPI(`/users/phases/${uuid}`)
      .then((res) => {
    
        if (res.status === 200) {
          this.setState({ newPhase: res.data.msg, loading: false });
        }
      })
      .catch((err) => {
        alert("Server error not getting all phases");
      });
  }
 
  
  StudyPhaseReload = () => {
    this.setState({ loading: true });
    this.componentDidMount();
  };

  render() {
    const { newPhase, loading } = this.state;
  

    return (
      <>
        {!loading ? (
          <div className="m-0 p-0 first_list">
            <Tabs className="tab_list_section tab_sub_list mt-0">
              <TabList className="nav-overflow">
                {newPhase.map((phase, index) => {
                  return <Tab key = {index}>{phase.name}</Tab>;
                })}
                <Tab data-toggle="modal" data-target="#AddPhaseModal">
                  Add Study Phase
                </Tab>
              </TabList>
              <div className="tab_body">
                {newPhase.map((phase, index) => {
                  return (
                    <TabPanel key  ={index}>
                      <NewPhase name={phase.name} {...this.state} />
                    </TabPanel>
                  );
                })}
              </div>
            </Tabs>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-dark  " role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <EditStudy />
        <DublicateVisitModal {...this.state}/>
        
        {/* <DublicateStudy /> */}
        <DeleteStudy />
        {/* <DownloadCPTM /> */}
        {/* <DownloadTracking {...this.state.uuid}/> */}
        {/* <TrackingModal /> */}
        <AddPhaseModal {...this.props} studyPhaseReload={this.StudyPhaseReload} />
      </>
    );
  }
}
export default StudyPhase;
