
import './App.css';
import MainRouter from './MainRouter/MainRouter';

function App() {
  return (
    <MainRouter />
  );
}

export default App;
