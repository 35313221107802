import React, { Component } from "react";
import { patchAPI } from "../../api/api-interface";
export class EditParticipants extends Component {
  state = {
    participant: [],
    patientName: "",
    patientStudy_id: "",
    md: "",
    mrn: "",
    id: "",
    input: false,
  };

  componentWillReceiveProps(nextProps) {
    const { editId } = nextProps;

    this.setState({ patientName: editId?.patientName, patientStudy_id: editId?.patientStudy_id, md: editId?.md, mrn: editId?.mrn, id: editId?.id });
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ input: false });
  };

  saveHandler = () => {
    const { patientName, patientStudy_id, md, mrn } = this.state;
    const data = { patientName, patientStudy_id, md, mrn };
    if (!patientName || !patientStudy_id || !md || !mrn) {
      this.setState({ input: true });
    } else {
      this.setState({ input: false });
      if (patientName && patientStudy_id && md && mrn) {
        patchAPI(`/users/editId/${this.state.id}`, data)
          .then((res) => {
            if (res.status === 200) {
              alert("Edit Successfully");
              this.props.editParticipantReload();
            }
          })
          .catch((err) => console.log("Not edited"));
      } else {
        this.setState({ input: true });
      }
    }
  };
  render() {
    return (
      <>
        <div className="modal fade" id="EditParticipant" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h1 className="modal-title w-100" id="exampleModalLabel">
                  Edit Participant
                </h1>
              </div>
              <div className="modal-body pb-0">
                <div className="form_container">
                  <form>
                    <div className="form-group">
                      <label htmlFor="visit_name">Patient Name</label>
                      <input type="text" className="form-control" id="patientName" name="patientName" placeholder="Patient Name" value={this.state.patientName} onChange={this.onChangeHandler} />
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-4 form-group">
                        <label htmlFor="duration">Study Id</label>
                        <input
                          type="text"
                          className="form-control"
                          id="patientStudy_id"
                          name="patientStudy_id"
                          placeholder="Study Id"
                          value={this.state.patientStudy_id}
                          onChange={this.onChangeHandler}
                        />
                      </div>
                      <div className="col-sm-12 col-md-3 form-group">
                        <label htmlFor="Tolerance_widows">MD</label>
                        <div className="d-flex align-items-center">
                          <input type="text" className="form-control form_input f_out" id="md" name="md" placeholder="MD" value={this.state.md} onChange={this.onChangeHandler} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5 form-group">
                        <label htmlFor="Tolerance_widows">MRN</label>
                        <div className="d-flex align-items-center">
                          <input type="text" className="form-control form_input f_out" name="mrn" id="mrn" placeholder="MRN" value={this.state.mrn} onChange={this.onChangeHandler} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary mx-auto"
                  data-dismiss={!this.state.patientName || !this.state.patientStudy_id || !this.state.md || !this.state.mrn ? "" : "modal"}
                  onClick={this.saveHandler}
                >
                  Save
                </button>
              </div>
              {this.state.input && (
                <div className="text-center">
                  <p className="text-danger">All Feilds are required</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditParticipants;
