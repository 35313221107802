import React from 'react'
import { useHistory } from 'react-router-dom'

 const NotFound = () => {
    const history = useHistory();
    return (
        <div className='container mt-5'>
            <h1>404 Not Found </h1>
            <button className='btn btn-primary' onClick={()=>history.push('/dashboard')}>Back to Dashboard</button>
        </div>
    )
}

export default NotFound