import React, { Component } from 'react';  
class StudyDetails extends Component {
   
    render() {
        const {anticipated_enrollment,principal_Investigator,nct_name,activation_date} = this.props
        
 
        return (
            <> 
             <div>  
                <div className="tab_table mt-4">
                    <table className="table mb-0">
                        <tbody>
                            <tr>
                                <td>NCT Name</td>
                                <td>{nct_name}</td>
                            </tr>
                            {/* <tr>
                                <td>Institute Number</td>
                                <td></td>
                            </tr> */}
                            <tr>
                                <td>Principal Investigator</td>
                                <td>{principal_Investigator}</td>
                            </tr>
                            {/* <tr>
                                <td>Study Grid</td>
                                <td></td>
                            </tr> */}
                            <tr>
                                <td>Activation Date</td>
                                <td>{activation_date}</td>
                            </tr>
                            <tr>
                                <td>Anticipated Enrollment</td>
                                <td>{anticipated_enrollment}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </>
        )
    }
}
export default (StudyDetails)
