import React, { Component } from "react";
import { patchAPI } from "../../api/api-interface";
// import TrackingModal from "../../Component/Modal/TrackingModal";

export default class SaveAndCancel extends Component {
  state = {
    fields: false,
    cancel: false,
    loading: false,
    cancelId: [],
    buton: true,
    downloadId: "",
    downloadIds: {},
  };
  handleSubmit = (action) => {
    const { arr, phaseName } = this.props;

    if (action === "save") {
      let fields = false;
      arr.forEach((el) => {
        const { visit_data } = el;
        visit_data.forEach((item) => {
          const { clinicalService, location, serviceUnit } = item;
          if (!clinicalService || !location || !serviceUnit) {
            fields = true;

            this.setState({ fields: true }, () => this.props.checkFields(true));
          }
        });
      });

      if (!fields) {
        this.setState({ loading: true });
        patchAPI(`/users/phase/${phaseName}`, { data: arr, studyUuid: this.props.studyUuid })
          .then((res) => {
            if (res.status === 200) {
              this.setState({ loading: false, buton: true });
              this.setState({ downloadIds: this.state.downloadId });
              alert("Data Saved Successfully");
            }
          })
          .catch((err) => {
            alert("Server Error , Try again");
          });
      }
    } else {
      this.props.cancelData(arr);
    }
  };

  changeValue = (val) => {
    this.setState({ buton: val });
  };
  componentWillReceiveProps(props) {
    this.setState({ buton: props.button, downloadId: props.downloadId });
  }

  render() {
    const { loading, buton } = this.state;

    return (
      <React.Fragment>
        <div className="save_cancel_button ">
          <button className="btn btn-primary btn-sm radious_4 save" onClick={() => this.handleSubmit("save")} disabled={(loading, buton)}>
            {this.state.loading ? "Saving..." : "Save"}
          </button>
          <div className="btn btn-secondary btn-sm radious_4 ml-3 save" onClick={() => this.handleSubmit("remove")}>
            Cancel
          </div>
        </div>
      </React.Fragment>
    );
  }
}
