import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Header from '../Component/Header/Header'
// import Footer from '../Component/Footer/Footer';

import Login from '../Pages/Account/Login';
import ForgetPassword from '../Pages/Account/ForgetPassword';
import SignUp from '../Pages/Account/SignUp';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Study from '../Pages/CaseStudy/AllCaseStudy';
import Settings from '../Pages/Account/Settings';
import axios from 'axios';
import { Baseurl } from '../api/baseurl';
import NotFound from './NotFound';

import ResetPassword from '../Pages/Account/ResetPassword';

class MainRouter extends Component {
    state = {
        authorized: false,
        permit: false,
        err: false,
        userEmail: '',
        loading: true

    }
    auth = (token) => {

        axios({
            method: 'get',
            url: Baseurl + '/users/authorize',
            headers: {
                'content-type': 'application/json',
                Authorization: ` Bearer ${token}`
            }


        }).then((res => {
            if (res.status === 200) {
               
                this.setState({ authorized: true, permit: true, userEmail: res.data, loading: false });


            } else {
                alert('Something went wrong. Please try again.')
            }

        })).catch((error) => {
            console.log("signup-with-api-error");
            localStorage.removeItem('myToken')

            // alert('Server error. Please try again.')
            this.setState({ permit: true, loading: false })
        })


    }
    componentDidMount() {
        const token = localStorage.getItem('myToken');
        if (token) {
            this.auth(token);

        }
        else {
            this.setState({ authorized: false, permit: true, loading: false });
        }

    }

    reload = () => {
        this.componentDidMount();
    }
    render() {
        const { authorized, permit, loading } = this.state;
     
        return (<div>

            {<Router>

                {/* <Route path='/'  render={(props) => authorized ?<Header {...props} reload={this.reload}/>: <Login reload={this.reload}/>} /> */}
                {authorized && <Header reload={this.reload} />}

                <Switch>

                    {!loading ? <Route exact path='/' render={() => authorized ? <Redirect to='/dashboard' /> : <Login reload={this.reload} />} /> : <div className='d-flex justify-content-center'>
                        <div className="spinner-border text-dark  " role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div>}
                    <Route path='/resetPassword' component={ResetPassword} />
                    <Route path='/forgetPassword' component={ForgetPassword} />
                    <Route path='/signUp' render={() => <SignUp reload={this.reload} />} />
                    {permit && <Route path='/dashboard' render={() => authorized ? <Dashboard reload={this.reload} {...this.state} /> : <Redirect to='/' />} />}
                    {permit && <Route path='/Study/:id' render={(props) => authorized ? <Study {...props} /> : <Redirect to='/' />} />}
                    {permit && <Route path='/Settings' render={() => authorized ? <Settings /> : <Redirect to='/' />} />}
                    <Route exact path='*' render={() => authorized ? <NotFound /> : <Login reload={this.reload} />} />

                    {/* <Route path='*' exact={true} component={My404Component} /> */}


                </Switch>
                {/* {/* <Footer />} */}
            </Router>}
        </div>
        )
    }
}

export default (MainRouter)