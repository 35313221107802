import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/img/logo1.svg"
// import { getAPI } from "../../api/api-interface";
import { getAPI } from "../../api/api-interface";

class Header extends Component {
  state = {
    redirect: false,
    user:''
  };
  logOut = () => {
    localStorage.removeItem("myToken");
    //  this.setState({ redirect: true })
    this.props.reload();
   
  };
  
  componentDidMount() {
    getAPI("/users/settings")
      .then((res) => {
  
        const { data } = res.data;
      
        const { name } = data;
        this.setState({  user: name,  });
      })
      .catch((err) => {
        err && alert(err);
      });
  }

  render() {
    return (
      <>
        <header className="d-flex p-3 align-itesms-center border-bottom">
          <div className="logo-section">
            <h2 className="mb-0">
              
              <Link to="/dashboard">
                <img
                src = {Logo}
                style = {{width:'150px', paddingLeft:"13px", height:"auto"}}
                alt = "logo"
                />
              </Link>
            </h2>
          </div>
          <div className="dropdown ml-auto cs_dropdown">
            <button className="btn dropdown-toggle p-0 header_cs_btn focus_out" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {this.state.user}
            </button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" >
              <Link to="/settings" className="dropdown-item">
                Settings
              </Link>
              <button
                onClick={() => {
                  this.logOut();
                }}
                className="dropdown-item"
              >
                Logout
              </button>
            </div>
          </div>
        </header>
      </>
    );
  }
}
export default Header;
