import React, { Component } from "react";
import { postAPI } from "../../api/api-interface";
import Select from 'react-select'
// import EditNewVisitModal from "./EditNewVisitModal";



const options = [
  { value: 'Days', label: 'Days' },
  { value: 'Weeks', label: 'Weeks' },
  { value: 'Months', label: 'Months' },
]

class AddNewVisitModal extends Component {
  state = {
    visit_name: "",
    duration: "",
    tolerance_window: '',
    fields: false,
    id: 0,
    selectedOption:{ value: 'Days', label: 'Days' },
    tolerance_option:{ value: 'Days', label: 'Days' },
  };
  handleSubmit = () => {
    const { uuid, name } = this.props;
    const { visit_name, selectedOption, tolerance_option} = this.state;
    let {duration, tolerance_window } =this.state
    console.log("TTTTTTTTTTTTtttt", selectedOption)
    if (!visit_name || !duration || !tolerance_window) {
      this.setState({ fields: true });
    } else {
      duration = duration+" "+selectedOption.label
      tolerance_window = tolerance_window+" "+tolerance_option.label
      console.log('type of:', selectedOption.label)
      console.log('type:', tolerance_option.label)
      const data = { studyUuid: uuid, visit_name, duration, tolerance_window };
     
     if(visit_name && duration && tolerance_window){
      postAPI(`/users/visitIntial/${name}`, data)
        .then((res) => {
         
          if (res.status === 200) 
          alert("New Visit Created");
          this.props.visitModalData();
        })
        .catch((err) => alert("Server Error , try again"));

      this.setState({ visit_name: "", duration: "", tolerance_window: '' });
     } else {
       this.setState({fields:true})
     }
    }
  };
  onChangeHandler = (e) => {
    if(e.target.value.length==4) return false;
    const reg = /^[0-9\b]+$/;
      if (e.target.value ==="" ||reg.test(e.target.value)) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.setState({ input: false });
        
      }
  };
  handleDurationOptions = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  handleToleranceOptions = (tolerance_option) => {
    this.setState({ tolerance_option }, () =>
      console.log(`Option selected:`, this.state.tolerance_option)
    );
  };

  render() {
    const {tolerance_window, duration, visit_name} = this.state
    return (
      <>
        <div className="modal fade" id="AddNewVisit" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h1 className="modal-title w-100" id="exampleModalLabel">
                  Add New Visit
                </h1>
                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button> */}
              </div>
              <div className="modal-body pb-0">
                <div className="form_container">
                  <form>
                    <div className="form-group">
                      <label htmlFor="visit_name">Visit Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="visit_name"
                        placeholder="Visit Name"
                        value={this.state.visit_name}
                        onChange={(e) => this.setState({ visit_name: e.target.value , fields:false})}
                      />
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 form-group new-select">
                        <label htmlFor="duration" className="fullwidth">Duration</label>
                        <input type="text" className="form-control form_input f_out select-gap" name="duration" id="duration" placeholder="2 " value={this.state.duration} onChange={this.onChangeHandler}   />
                        
                        <Select                        
                          className="form_input f_out "
                          value={this.state.selectedOption }
                          onChange={this.handleDurationOptions}
                          options={options}
                          defaultValue={options[0]}
                        />
                      </div>
                      
                      <div className="col-sm-12 col-md-6 form-group">
                        <label htmlFor="Tolerance_widows">Tolerance Windows</label>
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center mr-2">
                            <span className="fe fe-plus" />
                            <span className="slash"></span>
                            <span className="fe fe-minus" />
                          </div>
                          <input
                            type="text"
                            name="tolerance_window"
                            className="form-control form_input f_out select-gap"
                            id="Tolerance_widows"
                            placeholder="2"
                            value={this.state.tolerance_window}
                            onChange={this.onChangeHandler}  
                          />
                          <Select                        
                          className="form_input f_out"
                          value={this.state.tolerance_option }
                          onChange={this.handleToleranceOptions}
                          options={options}
                          defaultValue={options[0]}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary mx-auto" data-dismiss={!visit_name || !duration || !tolerance_window ? "" : "modal"} onClick={this.handleSubmit}>
                  Add Visit
                </button>
              </div>
              {this.state.fields && (
                <div className="text-center">
                  <p className="text-danger">Please fill all the details</p>
                </div>
              )}
            </div>
          </div>
        </div>
       
      </>
    );
  }
}
export default AddNewVisitModal;
