import React, { Component } from "react";
import { deleteAPI } from "../../api/api-interface";
import dublicate_image from "../../Assets/img/Delete_screen.svg";

class DeleteParticipant extends Component {
  state = {
    deleteId: "",
    delIndex: "",
    participant: [],
    index: "",
    feilds: [],
    id: "",
  };

  componentWillReceiveProps(nextProps) {
      const { deleteId } = nextProps;
      this.setState({ deleteId: deleteId });
  }

  deleteHandler = () => {
    const { id } = this.state.deleteId;

    if (id) {
       deleteAPI(`/users/${id}`).then((res) => {
        if (res.status === 200) {
          this.props.deleteReload();
        }
      });
    }
  };
  render() {
    return (
      <>
        <div className="modal fade" id="DeleteParticipant" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h1 className="modal-title w-100" id="exampleModalLabel">
                  Delete Study
                </h1>
              </div>
              <div className="modal-body pb-0">
                <div className="row">
                  <div className="col-sm-12 text-center main_wrapper">
                    <div className="dublicate_image">
                      <img src={dublicate_image} alt="dublicate" className="img-fluid" />
                    </div>
                    <p className="mb-4">Do you really want to delete this study? This will delete all data of this study. Once deleted, the study can not be recovered.</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary px-5" data-dismiss="modal">
                  No
                </button>
                <button type="button" className="btn btn-primary px-5 ml-4" data-dismiss="modal" onClick={this.deleteHandler}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default DeleteParticipant;
