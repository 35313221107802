import React, { Component } from 'react';
import dublicate_image from '../../Assets/img/Export.svg'

class Download extends Component {
    state = {
        isShow:false
    }
    downloadTracking = () => {
        this.setState({isShow:true})
    }
    
    
    render() {
        // console.log("cptm data", this.props)
        return (
            <>
            <div className="modal fade" id="Download" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h1 className="modal-title w-100" id="exampleModalLabel">Download</h1> 
                        </div>
                        <div className="modal-body pb-0">                            
                            <div className="row">
                                <div className="col-sm-12 text-center main_wrapper">
                                    <div className="dublicate_image">
                                        <img src={dublicate_image} alt="dublicate" className="img-fluid" />
                                    </div>
                                    <button type="button" className="btn btn-primary px-5 ml-4" data-toggle="modal" data-target="#DownloadTracking" data-dismiss="modal">Download Tracking</button>
                               
                                   <button type="button" className="btn btn-primary px-5 ml-4" data-toggle="modal" data-target="#DownloadCPTM" data-dismiss="modal" >Download CPTM</button>
                                  
                                </div>

                            </div>
                        </div>
                
                    </div>
                </div>
            </div>
        </>
        )
    }
}
export default (Download)
