import React, { Component } from "react";
import InputMask from "react-input-mask";
import { patchAPI } from "../../api/api-interface";

class EditStudy extends Component {
  state = {
    study_name: "",
    nct_name: "",
    principal_Investigator: "",
    anticipated_enrollment: "",
    activation_date: "",
    formField: true,
    input:false
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({input:false})
  };
  componentWillReceiveProps(nextProps) {
    const { study_name, nct_name, principal_Investigator, anticipated_enrollment, activation_date } = nextProps;
    this.setState({ study_name: study_name, nct_name: nct_name, principal_Investigator: principal_Investigator, anticipated_enrollment: anticipated_enrollment, activation_date: activation_date });
  }

  editHandler = () => {
    this.setState({ formField: true });
    const { study_name, nct_name, principal_Investigator, anticipated_enrollment, activation_date } = this.state;
    const data = { study_name, nct_name, principal_Investigator, anticipated_enrollment, activation_date };

    if(!study_name || !nct_name || !principal_Investigator || !anticipated_enrollment || !activation_date){
      this.setState({input:true})
      this.setState({formField:false})
    }else{
      this.setState({input:false})
      patchAPI(`/users/study/${this.props.studyUuid}`, data)
      .then((res) => {
       
        if (res.status === 200) {
          alert("Edit successfully");
          window.location.reload();
        }
      })
      .catch((err) => {
        alert(err);;
      });

    }

  
  };

  formActive = () => {
    this.setState({ formField: false });
  
  };

  render() {
    const { study_name, nct_name, anticipated_enrollment, activation_date, principal_Investigator, formField } = this.state;

    return (
      <>
        <div className="modal fade" id="EditStudy" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h1 className="modal-title w-100" id="exampleModalLabel">
                  Edit Study
                </h1>
                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button> */}
              </div>
              <div className="modal-body pb-0">
                <div className="form_container">
                  <form>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Study Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Student Name"
                        name="study_name"
                        value={study_name}
                        disabled={formField}
                        onChange={this.changeHandler}
                      />
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 form-group">
                        <label htmlFor="NCT">NCT Name</label>
                        <input type="text" className="form-control" id="NCT" placeholder="04902157" name="nct_name" value={nct_name} disabled={formField} onChange={this.changeHandler} />
                      </div>
                      <div className="col-sm-12 col-md-6 form-group">
                        <label htmlFor="principal_investigator">Principal Investigator</label>
                        <input
                          type="text"
                          className="form-control"
                          id="principal_investigator"
                          placeholder="Jone Doe"
                          name="principal_Investigator"
                          value={principal_Investigator}
                          disabled={formField}
                          onChange={this.changeHandler}
                        />
                      </div>
                    </div>
                    {/* <div className="row">
                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label htmlFor="Insttitute">Institute Number</label>
                                                <input type="text" className="form-control" id="Insttitute" placeholder="326598" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label htmlFor="Study_grid">Study Grid</label>
                                                <input type="text" className="form-control" id="Study_grid" placeholder="326598" />
                                            </div>
                                        </div> */}
                    <div className="row mb-0">
                      <div className="col-sm-12 col-md-6 form-group">
                        <label htmlFor="activation_date">Activation Date</label>
                        {/* <input type="text" className="form-control" id="activation_date" placeholder="06/22/2021" /> */}
                        {/* <input type="text" class="form-control" placeholder="__/__/____" data-inputmask="'mask': '99/99/9999'"></input> */}
                        <InputMask
                          mask="99/99/9999"
                          className="form-control"
                          id="activation_date"
                          alwaysShowMask="true"
                          name="activation_date"
                          value={activation_date}
                          disabled={formField}
                          onChange={this.changeHandler}
                          beforeMaskedValueChange={this.beforeMaskedValueChange}
                        ></InputMask>
                      </div>
                      <div className="col-sm-12 col-md-6 form-group">
                        <label htmlFor="anticipated_enrollment">Anticipated Enrollment</label>
                        <input
                          type="text"
                          className="form-control"
                          id="anticipated_enrollment"
                          placeholder="50"
                          name="anticipated_enrollment"
                          value={anticipated_enrollment}
                          disabled={formField}
                          onChange={this.changeHandler}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary mx-auto" onClick={!formField ? this.editHandler : this.formActive}>
                  {formField ? "Click for Edit Study Details" : "Save Study Details"}
                </button>
              </div>
              {this.state.input && <div className="text-center">
                <p className='text-danger'>All Feilds are required</p>
              </div>}

            </div>
          </div>
        </div>
      </>
    );
  }
}
export default EditStudy;
