import React, { Component } from "react";
import Tracking from "../../Pages/CaseStudy/Tracking";
import { PDFExport } from "@progress/kendo-react-pdf";


class TrackingModal extends Component {
  
 render() {
    return (
      <>
        <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div className="modal-dialog tracking_modal_lg " role="document">
            <div className="modal-content">
              {/* <button className="btn btn-white lift " onClick={this.generatePdf}>
                Download PDF
              </button> */}
              <button className="btn btn-white lift " onClick={() => { this.pdfExportComponent.save()}}>
                Download PDF
              </button>
             
              <PDFExport
               scale={0.6}
               keepTogether="p"
               paperSize="A4"
               fileName="Report.pdf"
               margin="1cm"
               forcePageBreak=".page-break"
               ref={(component) => this.pdfExportComponent = component}
              >
              <div id="downloadPdf">
                <div className="modal-header text-center">
                  <div className="profile_tracking">
                    <div className="avatar">
                      {/* <img className="avatar-img rounded-circle" src={Avatar} alt="..." /> */}
                    </div>
                  </div>
                  <h1 className="modal-title w-100" id="exampleModalLongTitle">
                    Tracking Form
                  </h1>
                </div>
                <div className="modal-body pb-0">
                  <div className="wp_tracking">
                    <Tracking {...this.props} />
                  </div>
                </div>
              </div>
              </PDFExport>
             
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default TrackingModal;
