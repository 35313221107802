import React, { Component } from "react";
import { getAPI } from "../../api/api-interface";
import { PDFExport } from "@progress/kendo-react-pdf";

export class TrackingNew extends Component {
  state = {
    downloadData: [],

    currentDateTime: Date().toLocaleString(),
    hospitalName: "",
    firstName: "",
    lastName: "",
    preScreening: "",
    study_name: "",
    newArray: [],
  };

  componentDidUpdate(preProps) {
    if (preProps !== this.props) {
      const { downloadData } = this.props;
      this.setState({ downloadData });

      const arr = this.props?.downloadData?.visit_data?.map((el) => el.serviceUnit);
      const arr1 = [...new Set(arr)];
      const newArr = arr1
        ?.map((el) => {
          let a = 0;
          if (el === "Laboratory Test") a = 1;
          if (el === "Research Procedure") a = 2;
          if (el === "Imaging") a = 3;
          if (el === "Pathology") a = 4;
          if (el === "Treatment") a = 5;
          if (el === "Assessment") a = 6;
          return { [el]: this?.props?.downloadData.visit_data.filter((vi) => vi.serviceUnit === el), order: a };
        })
        .sort((a, b) => a.order - b.order);

      this.setState({ newArr: newArr, arr1: arr1 });
    }
  }

  componentDidMount() {
    getAPI("/users/settings")
      .then((res) => {
        const { data } = res.data;

        const { hospitalName, firstName, lastName } = data;
        this.setState({ hospitalName, firstName, lastName });
      })
      .catch((err) => {
        err && alert(err);
      });

    const arr = this.props?.downloadData?.visit_data?.map((el) => el.serviceUnit);
    const arr1 = [...new Set(arr)];
    const newArr = arr1
      ?.map((el) => {
        let a = 0;
        if (el === "Laboratory Test") a = 1;
        if (el === "Research Procedure") a = 2;
        if (el === "Imaging") a = 3;
        if (el === "Pathology") a = 4;
        if (el === "Treatment") a = 5;
        if (el === "Assessment") a = 6;
        return { [el]: this?.props?.downloadData?.visit_data?.filter((vi) => vi?.serviceUnit === el), order: a };
      })
      .sort((a, b) => a.order - b.order);

    this.setState({ newArr: newArr, arr1: arr1 });
  }

  render() {
    const { downloadData } = this.state;
    return (
      <div className="" style={{ marginTop: "1000px" }}>
        <div id={`downloadTrackingnew-` + this.props?.index}>
          <div className="tracking_modal_lg" role="document">
            <div className="my-4">
              <div className="modal-header text-center">
                <div className="profile_tracking">
                  <div className="avatar"></div>
                </div>
                <span>
                  <h4 className="modal-title w-100" id="exampleModalLongTitle">
                    {this?.props?.downloadData.visit_name}
                  </h4>
                </span>
                <h1 className="modal-title w-100" id="exampleModalLongTitle">
                  Tracking Form
                </h1>
                <span>
                  <h4 className="modal-title w-100" id="exampleModalLongTitle">
                    {this?.props?.downloadData.study_phase}
                  </h4>
                </span>
              </div>
              <div className="modal-body pb-0">
                <div className="wp_tracking">
                  <div className="wp_tracking">
                    <div className="header">
                      <div className="header-body pb-0 pt-0 mt-0">
                        <div className="row align-items-center">
                          <div className="col">
                            <h6 className="header-pretitle">{this.state?.hospitalName}</h6>
                            <h3 className="header-title mb-4">{downloadData?.study_name}</h3>
                          </div>
                          <div className="col-auto "></div>
                        </div>
                      </div>
                    </div>
                    <div className="card_body mb-5">
                      <div className="row form_label">
                        <div className="col-sm-12 col-md-3 pr_small">
                          <div className="form-group d-flex align-items-end ">
                            <label htmlFor="patient_name" className="mb-0">
                              Patient Name:
                            </label>
                            <input type="text" className="form-control border-0 input_tracking f_out" id="patient_name" disabled />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3 pr_small">
                          <div className="form-group d-flex align-items-end">
                            <label htmlFor="study_id" className="mb-0">
                              Study Id:
                            </label>
                            <input type="text" className="form-control border-0 input_tracking f_out" id="study_id" disabled />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3 pr_small">
                          <div className="form-group d-flex align-items-end">
                            <label htmlFor="id" className="mb-0">
                              MD:
                            </label>
                            <input type="text" className="form-control border-0 input_tracking f_out" id="id" disabled />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="form-group d-flex align-items-end">
                            <label htmlFor="MRN" className="mb-0">
                              MRN:
                            </label>
                            <input type="text" className="form-control border-0 input_tracking f_out" id="MRN" disabled />
                          </div>
                        </div>
                      </div>
                      <div className="row" id="PPP">
                        <div className="col-12">
                          {this.state?.newArr
                            ? this.state?.newArr?.map((vis) => {
                                return (
                                  <div className="table-responsive table_">
                                    {Object.keys(vis)[0] === "Laboratory Test" ? (
                                      <small className = "text-dark">Blood Specimen: Laboratory Test</small>
                                    ) : (
                                      <small className="text-dark">{Object.keys(vis)[0]}</small>
                                    )}
                                    <table className="table">
                                      <tbody>
                                        {this.state?.arr1.some((ar) => ar === Object.keys(vis)[0]) &&
                                          vis[Object.keys(vis)[0]].map((item, index) => {
                                            return (
                                              <tr key={index}>
                                                <td className="td_wid_tracking">
                                                  {item.clinicalService} {Object.keys(vis)[0] === "Laboratory Test" && `(${item.comments})`}
                                                </td>
                                                <td className="">
                                                  <div className="d-flex align-items-center">
                                                    <div className="form-check form-check-inline">
                                                      <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" disabled />
                                                      <label className="form-check-label" htmlFor="inlineCheckbox1">
                                                        Yes
                                                      </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                      <input className="form-check-input" type="checkbox" id="inlineCheckbox2" defaultValue="option1" disabled />
                                                      <label className="form-check-label" htmlFor="inlineCheckbox2">
                                                        No
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                );
                              })
                            : ""
                          }
                          <h6 className="text-uppercase">Notes</h6>
                          <p className="text-muted mb-0">This tracking Form was generated using Concordare on {this.state.currentDateTime}.Copyright Concordaretrials.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TrackingNew;
