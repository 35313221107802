import axios from "axios";
import { Baseurl } from "./baseurl";


export const getAPI =(path)=>{
const token = localStorage.getItem('myToken')
   return axios({
        method: 'get',
        url: Baseurl + path,
        headers: {
            'content-type': 'application/json',
            'Authorization' : `Bearer ${token}`
        }

    })

}
export const deleteAPI =(path)=>{
    const token = localStorage.getItem('myToken')

    return axios({
         method: 'delete',
         url: Baseurl + path,
         headers: {
             'content-type': 'application/json',
            'Authorization' : `Bearer ${token}`

             
         }
 
     })
 
 }
export const patchAPI =(path,data)=>{
    const token = localStorage.getItem('myToken')

    return axios({
         method: 'patch',
         url: Baseurl + path,
         headers: {
             'content-type': 'application/json',
            'Authorization' : `Bearer ${token}`

         },data
 
     })
 
 }

export const postAPI =(path,data)=>{
    const token = localStorage.getItem('myToken')

    return axios({
         method: 'post',
         url: Baseurl + path,
         headers: {
             'content-type': 'application/json',
            'Authorization' : `Bearer ${token}`

         },data
 
     })
 
 }