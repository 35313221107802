import React, { Component } from 'react'  
import {withRouter } from 'react-router-dom'; 
import {parse} from 'papaparse'
import { postAPI } from '../../api/api-interface';

class UploadModal extends Component {
    state = {
        isStudyExist:false
    }
    
    render() {
        return (
            <>
                <div className="modal fade" id="UploadModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h1 className="modal-title w-100" id="exampleModalLabel">Upload CPTM</h1> 
                            </div>
                            <div className="modal-body pt-5 pb-4"> 
                                <div className="dropzone dropzone-single mb-3 dz-clickable" data-dropzone="{&quot;url&quot;: &quot;https://&quot;, &quot;maxFiles&quot;: 1, &quot;acceptedFiles&quot;: &quot;image/*&quot;}"> 
                                    <div className="dz-preview dz-preview-single"></div>
                                    <div className="dz-default dz-message"
                                        onDragOver = {(e)=>{e.preventDefault()}} 
                                        onDrop = {(e)=>{e.preventDefault()   
                                            
                                        // console.log("drop",e.dataTransfer.files)
                                        Array.from(e.dataTransfer.files).filter((file)=>file.type === "text/csv")
                                        .forEach(async(file)=>{const text = await file.text()
                                            // console.log(text)
                                            const result = parse(text, {header:true})
                                            const newResult = result.data[0]
                                            // console.log("result", result.data[0])
                                            postAPI('/users/cptmUpload', result).then((res) => {
                                              
                                                if (res.status === 200) {
                                                    // this.setState({loading:false})
                                                    alert("File Uploaded Successfully")
                                                    window.location.reload();
                                                    
                                                }
                                                else if (res.status === 202) {
                                                
                                                    this.setState({ isStudyExist: true });
                                                    // this.setState({loading:false})
                                                
                                                }
                                            }).catch((error) => {
                                                alert(error)
                                            })
                                        })
                                        } }
                                     
                                    >
                                        <button className="dz-button" type="button">Drop files here to upload</button>
                                    </div>
                                </div>
                            </div>
                            {this.state.isStudyExist && <div className="text-center">
                                    <p className='text-danger'>This study with same User is already exist</p>
                                </div>}
                            {/* <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-secondary px-5">No</button>
                                <button type="button" className="btn btn-primary px-5 ml-4" onClick={() => setTimeout(() => {
                                    this.props.history.push('/dashboard')
                                }, 300)}>Yes</button>
                            </div> */}
                        </div>
                    </div>
                </div>


            </>
        )
    }
}
export default withRouter(UploadModal)