import React, { Component } from 'react'
import { Link} from 'react-router-dom';

import { postAPI } from '../../api/api-interface';


class Login extends Component {
    state = {
        email: '',
        password: '',
        error: false,
        redirect: false,
        dataExist: false,
        // isDataExist:false,
        slashPasswordIcon: false,
        fields: false,
        isWrongPassword: false,
        invalidEmail:false
    }
    validateEmail(email){
    // const validateEmail = /^([a-z\d.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
    const validateEmail = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
   
    return validateEmail.test(String(email).toLowerCase());
    }
    
    loginAccountAPI = async(loginData) => {

      

        /******** Login DB api call ******/
     
            postAPI('/users/login', loginData).then((res => {
                let loginApiResponseIs = res.status;
                
               
                
                if (loginApiResponseIs === 200) {
                    localStorage.setItem('myToken', res.data.token);
                    this.props.reload();



                }
               else if (loginApiResponseIs === 204) {
                // alert("Email Does not exist")
                this.setState({dataExist:true})
                // this.setState({isDataExist:true})
                // localStorage.setItem('myToken', res.data.token);

                    this.props.reload();
                }
                else{
                    this.setState({dataExist:false})
                    this.props.reload();
                }
            })).catch((error) => {
                console.log(error);
                this.setState({dataExist:true})

            })
           
        


    }
    displayPassword = (e) => {
        e.preventDefault();
        const password = document.getElementById('password');
        if (password.type === 'password') {
            password.type = 'text';
            this.setState({ slashPasswordIcon: true })
        } else {
            password.type = 'password'
            this.setState({ slashPasswordIcon: false })


        }

    }

    logIn = (e) => {
        const {email} = this.state
        e.preventDefault();
        if (!this.state.email || !this.state.password) {
            this.setState({ fields: true })
        }
        else if(!this.validateEmail(email)){
            this.setState({ invalidEmail: true })
            

        }
        else{
          const data = { email: this.state.email, password: this.state.password }
          this.loginAccountAPI(data);

        }

    }
    render() {
       

        return (
            <>
                <div className="d-flex align-items-center bg-auth border-top border-top-2 border-primary h_100vh">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-5 col-xl-4 my-5">
                                <h1 className="display-4 text-center mb-3">Sign in</h1>
                                <p className="text-muted text-center mb-5">
                                    Free access to our dashboard.
                                </p>
                                <form>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="email" className="form-control" placeholder="Name@address.com" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value, dataExist: false, fields: false ,invalidEmail:false,  isDataExist:false})} />
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <label>Password</label>
                                            </div>
                                           
                                        </div>
                                        <div className="input-group input-group-merge">
                                            <input type="password" id='password' className="form-control form-control-appended" placeholder="Enter your password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value, dataExist: false, fields: false, isWrongPassword: false,  isDataExist:false })} />
                                            <div onClick={this.displayPassword} className="input-group-append">
                                                <span className="input-group-text">

                                                    {!this.state.slashPasswordIcon ?  <i className="far fa-eye-slash"></i> : <i className="fe fe-eye" />}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                                <Link to="/forgetPassword" className="form-text small forget ">
                                                    Forgot password?
                                                </Link>
                                            </div>
                                    </div>
                                    <button className="btn btn-lg btn-block btn-primary mb-3" onClick={e => this.logIn(e) }>Sign in</button>
                                    {this.state.dataExist && <div className="text-center">
                                        <p className='text-danger'>Enter the correct email and password</p>
                                    </div>}
                                    {/* {this.state.isDataExist && <div className="text-center">
                                        <p className='text-danger'>Please sign up to continue</p>
                                    </div>} */}
                                    {this.state.fields && <div className="text-center">
                                        <p className='text-danger'>Enter the Input field</p>
                                    </div>}
                                    {this.state.isWrongPassword && <div className="text-center">
                                        <p className='text-danger'>Enter the correct password</p>
                                    </div>}
                                    {this.state.invalidEmail && <div className="text-center">
                                        <p className='text-danger'>Enter the valid email</p>
                                    </div>}
                                    <div className="text-center">
                                        <small className="text-muted text-center">
                                            Don't have an account yet? <Link to="/signUp">Sign up</Link>.
                                        </small>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
             
            </>
        )
    }
}


export default (Login)
