import React, { Component } from "react";
import { patchAPI } from "../../api/api-interface";


class AddPhaseModal extends Component {
  state = {
    name: "",
    uuid: "",
    isName: false,
    success: false,
  };
  componentDidMount() {
    const { location } = this.props;
    const [, , uuid] = location.pathname.split("/");

    this.setState({ uuid });
  }
  addPhase = () => {
    const { name, uuid } = this.state;
    if (name) {
      patchAPI(`/users/phases/${uuid}`, { name })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ name: "" });
            this.props.studyPhaseReload();
            alert("Phase Created");

            // this.setState({success:true})
            // this.setState({success:true})
            // setTimeout(() => {
            // }, 3000);
          }
        })
        .catch((error) => {
          alert("server Error,Try again");
        });
    } else {
      this.setState({ isName: true });
    }
  };
  render() {
    return (
      <>
        {/* { this.state.success ? <Success success = "Success"/>:''} */}
        <div className="modal fade" id="AddPhaseModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h1 className="modal-title w-100" id="exampleModalLabel">
                  Add Phase
                </h1>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12">
                    <form>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Phase Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Phase Name"
                          value={this.state.name}
                          onChange={(e) => this.setState({ name: e.target.value, isName: false })}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary px-5" data-dismiss="modal">
                  Cancel
                </button>
                <button type="button" className="btn btn-primary px-5 ml-4" data-dismiss={!this.state.name ? "" : "modal"} onClick={this.addPhase}>
                  Ok
                </button>
              </div>
              {this.state.isName && (
                <div className="text-center">
                  <p className="text-danger">Please Enter Phase Name</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AddPhaseModal;
